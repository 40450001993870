import { OTP_RESPONSE,MOBILE_OTP_RESPONSE } from "./ActionTypes";

const initialState = {
  OTPvalidation: "",
  MobileOTPValidation:"",
};

const OTPVerification = (state = initialState, action) => {
  switch (action.type) {
    case OTP_RESPONSE:
      state = {
        ...state,
        OTPvalidation: action.payload,
      };
      break;
    case MOBILE_OTP_RESPONSE:
        state = {
          ...state,
          MobileOTPValidation: action.payload,
        };
        break;
    default:
      state = { ...state };
      break;
  }
  return state;
};

export default OTPVerification;
