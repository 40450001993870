import { REGISTRATION } from "./ActionTypes";
import { REGISTRATION_RESPONSE } from "./ActionTypes";


export const userCreate = (CreateUserInfo) => ({
  type: REGISTRATION,
  payload: CreateUserInfo,
});

export const userCreateResponse = (CreateUserResponse) => ({
  type: REGISTRATION_RESPONSE,
  payload: CreateUserResponse,
});

