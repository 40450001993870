import React, { useEffect, useState } from "react";
import "./Booking.scss";
import { ReactComponent as PhoneIcon } from "../../../assets/SvgIcons/PhoneIcon.svg";
import { ReactComponent as ImagePlaceHolderIcon } from "../../../assets/SvgIcons/ImagePlaceHolderIcon.svg";
import { ReactComponent as BookNowRightIcon } from "../../../assets/SvgIcons/BookNowRightIcon.svg";
import { ReactComponent as RightArrowIcon} from "../../../assets/SvgIcons/RightArrow.svg";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import Modal from "@mui/material/Modal";
import {
  Container,
  FormControl,
  MenuItem,
  Step,
  StepLabel,
  Stepper,
  TextField,
} from "@mui/material";
import dayjs from "dayjs";
import { DemoContainer } from "@mui/x-date-pickers/internals/demo";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DateCalendar } from "@mui/x-date-pickers/DateCalendar";
import ToggleButton from "@mui/material/ToggleButton";
import ToggleButtonGroup from "@mui/material/ToggleButtonGroup";
import OutlinedInput from "@mui/material/OutlinedInput";
import InputAdornment from "@mui/material/InputAdornment";
import { ReactComponent as AccountHumanIcon } from "../../../assets/SvgIcons/AccountHumanIcon.svg";
import { ReactComponent as HomeIcon } from "../../../assets/SvgIcons/HomeIcon.svg";
import { ReactComponent as LocationIcon } from "../../../assets/SvgIcons/LocationIcon.svg";
import { ReactComponent as LocationLocateIcon } from "../../../assets/SvgIcons/LocationLocateIcon.svg";
import { ReactComponent as SuccessBookingHomeIcon } from "../../../assets/SvgIcons/SuccessBookingHomeIcon.svg";
import { ReactComponent as SuccessBookingDateIcon } from "../../../assets/SvgIcons/SuccessBookingDateIcon.svg";
import { ReactComponent as SuccessBookingTimeIcon } from "../../../assets/SvgIcons/SuccessBookingTimeIcon.svg";
import { ReactComponent as EditIcon } from "../../../assets/SvgIcons/EditIcon.svg";
import MuiPhoneNumber from "material-ui-phone-number";
import SuccessImage from "../../../assets/images/SuccessImage.png";
import Breadcrumbs from "@mui/material/Breadcrumbs";
import NavigateNextIcon from "@mui/icons-material/NavigateNext";
import Firstcard from "../../../assets/images/Firstcard.png";
import secondcardTwo from "../../../assets/images/secondcardTwo.png";
import thirdcard from "../../../assets/images/thirdcard.png";
import secondcard from "../../../assets/images/secondcard.png";
import { Link } from "react-router-dom";
import Calendar from "react-calendar";
import { useDispatch, useSelector } from "react-redux";
import { createBooking, addBooking } from "../../../Redux/Actions";
import { toast } from "react-toastify";
import { LinkTwoTone, Today } from "@mui/icons-material";
import axios from "axios";
import bookingTechnicianImage from "../../../assets/images/bookingTechnician.png";
import orderTruck from "../../../assets/images/orderTruck.png";
import waterTank from "../../../assets/images/waterTank.png";
// import utc from 'dayjs/plugin/utc';
import timezone from 'dayjs/plugin/timezone';

// dayjs.extend(utc);
dayjs.extend(timezone);

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: "75%",
  
  height: "90%",
  bgcolor: "background.paper",
  border: "1px solid #c8c5c5",
  boxShadow: 24,
  p: 4,
  borderRadius: 5,
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
  justifyContent: "center",
};
const styleChild = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: "auto",
  height: "auto",
  bgcolor: "background.paper",
  border: "1px solid #c8c5c5",
  boxShadow: 24,
  p: 4,
  borderRadius: 5,
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
  justifyContent: "center",
};

const getCurrentTimeInMinutes = () => {
  const now = new Date();
  return now.getHours() * 60 + now.getMinutes();
};

// Function to convert time string (e.g., '10:00AM') to minutes
const timeToMinutes = (time) => {
  const [hour, minute] = time.split(":");
  const period = time.slice(-2); // 'AM' or 'PM'
  let hoursIn24Format = parseInt(hour, 10);

  if (period === "PM" && hoursIn24Format !== 12) {
    hoursIn24Format += 12;
  }
  if (period === "AM" && hoursIn24Format === 12) {
    hoursIn24Format = 0;
  }

  return hoursIn24Format * 60 + parseInt(minute, 10);
};

// Function to check if a time slot is in the past
const isPastTime = (time, selectedDate) => {
  const [hours, minutes] = time.split(':');
  const period = time.slice(-2); // 'AM' or 'PM'
  let hoursIn24Format = parseInt(hours, 10);

  if (period === "PM" && hoursIn24Format !== 12) {
    hoursIn24Format += 12;
  }
  if (period === "AM" && hoursIn24Format === 12) {
    hoursIn24Format = 0;
  }

  const timeInMinutes = hoursIn24Format * 60 + parseInt(minutes, 10);
  const now = new Date();
  const nowTimeInMinutes = now.getHours() * 60 + now.getMinutes();
  const selectedDateTime = new Date(selectedDate);
  const selectedDateStr = selectedDateTime.toDateString();
  const nowDateStr = now.toDateString();

  if (selectedDateStr === nowDateStr) {
    return timeInMinutes < nowTimeInMinutes;
  }

  return false;
};





const Booking = () => {
  const [open, setOpen] = useState(false);
  const [selectedItemId, setSelectedItemId] = useState(null); // Track selected item

  const handleOpen = (itemId) => {
    setOpen(true);
    setSelectedItemId(itemId); // Store the selected item
  };

  const handleClose = () => {
    setOpen(false);
    setSelectedItemId(null); // Clear selected item when closing
  };
  const dispatch = useDispatch();

  const [openModel, setOpenMadel] = useState(false);
  const [modelSteps, setModelSteps] = useState("firstStep");

  const handleOpenModel = () => {
    setOpenMadel(true);
  };
  const handleCloseModel = () => {
    setOpenMadel(false);
  };

  const [bookingTypeAutoFill, setBookingTypeAutoFill] = useState();

  const createBookingDetails = useSelector(
    (booking) => booking.Booking.createBookingDetails
  );

  const [alignment, setAlignment] = useState("");

  const handleChange = (event, newAlignment) => {
    setAlignment(newAlignment);
  };

  const [openChild, setOpenChild] = useState(false);
  const haandleOpen = () => {
    setOpenChild(true);
  };
  const haandleClose = () => {
    setOpenChild(false);

  };

  console.log(createBookingDetails, "createBookingDetails");

  const DateTimeHeaderStyle = {
    color: "#1F1F1F",
    fontFamily: "Figtree",
    fontSize: "18px",
    fontStyle: "normal",
    fontWeight: "500",
    lineHeight: "24px",
  };

  const MorAftEveStyle = {
    color: "#4D8BFF",
    fontFamily: "Figtree",
    fontSize: "14px",
    fontStyle: "normal",
    fontWeight: "500",
    lineHeight: "20px",
  };

  const [selectedDate, setSelectedDate] = useState();

  const breadcrumbs = [
    <Typography key="3" color="text.primary">
      <div className="popupImageContainer">
        <img src={Firstcard} alt="" className="popupImage" />{" "}
        <p className="popupImageText">Fill in general information</p>
      </div>
    </Typography>,
    <Typography key="3" color="text.primary">
      <div className="popupImageContainer">
        <img src={secondcardTwo} alt="" className="popupImage" />{" "}
        <p className="popupImageText">Choose Date & Time</p>
      </div>
    </Typography>,
    <Typography key="3" color="text.primary">
      <div className="popupImageContainer">
        <img src={thirdcard} alt="" className="popupImage" />{" "}
        <p className="popupImageText">Book your service</p>
      </div>
    </Typography>,
  ];

  const breadcrumbsData = [
    <Typography key="3" color="text.primary">
      <div className="popupImageContainer">
        <img src={Firstcard} alt="" className="popupImage" />{" "}
        <p className="popupImageText">Fill in general information</p>
      </div>
    </Typography>,
    <Typography key="3" color="text.primary">
      <div className="popupImageContainer">
        <img src={secondcard} alt="" className="popupImage" />{" "}
        <p className="popupImageText">Choose Date & Time</p>
      </div>
    </Typography>,
    <Typography key="3" color="text.primary">
      <div className="popupImageContainer">
        <img src={thirdcard} alt="" className="popupImage" />{" "}
        <p className="popupImageText">Book your service</p>
      </div>
    </Typography>,
  ];

  
  const authUser = JSON.parse(localStorage.getItem("auth"));
  console.log("authUserauthUserauthUser",authUser?.userId)


  const [modelData, SetModelData] = useState({
    firstname: "",
    lastname: "",
    phonenumber: "",
    description: "test",
    bookingaddress: "",
    bookingdatetime: dayjs(),
    selectTime: alignment,
    // selectedDate: "",
    bookingtype: "",
    userid: authUser?.userId,
  });

  const today = dayjs().startOf("day");

  // Function to check if the date is a Sunday
  const isDisabledDate = (date) => {
    return date.day() === 0; // 0 represents Sunday in dayjs
  };

  const [firstNameError, setFirstNameError] = useState(false);
  const [lastNameError, setLastNameError] = useState(false);
  const [phoneError, setPhoneError] = useState(false);
  const [addressError, setAddressError] = useState(false);
  const [bookingdatetimeError, setBookingdatetimeError] = useState(false);
  const [bookingtypeError, setBookingtypeError] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");

  const handleTimeConfrim =()=>{
    if (!alignment) {
      setErrorMessage("Please select a time slot."); // Show error message
      return; // Prevent navigation
    }
    // setErrorMessage(""); // Clear the error message
    // setModelSteps(""); // Proceed to next step
  }
  

  const handleContinue = () => {
    let isValid = true;

    if (!modelData.firstname) {
      setFirstNameError(true);
      isValid = false;
    } else {
      setFirstNameError(false);
    }

    if (!modelData.lastname) {
      setLastNameError(true);
      isValid = false;
    } else {
      setLastNameError(false);
    }

    if (!modelData.phonenumber) {
      setPhoneError(true);
      isValid = false;
    } else {
      setPhoneError(false);
    }

    if (!modelData.bookingaddress) {
      setAddressError(true);
      isValid = false;
    } else {
      setAddressError(false);
    }

    if (isValid) {
      setModelSteps("secondStep");
    }
  };

  const [date, setData] = useState(new Date());

  useEffect(() => {
    SetModelData({
      ...modelData,
      selectTime: alignment,
       
    });
  }, [alignment]);


 // Function to handle time selection and convert to 24-hour format
 const handleTimeSelection = (selectedTime) => {
  const selectedDate = modelData.bookingdatetime; // Current booking date as a Day.js object

  if (selectedDate && selectedTime) {
    // Parse selectedTime (e.g., '10:00AM')
    const [time, period] = selectedTime.split(/(AM|PM)/); // Split into time and period
    let [hours, minutes] = time.split(':').map(Number); // Get hours and minutes

    // Convert to 24-hour format
    if (period === "PM" && hours !== 12) {
      hours += 12;
    } else if (period === "AM" && hours === 12) {
      hours = 0;
    }

    // Combine selected time with the selected date
    const updatedDateTime = dayjs(selectedDate)
      .set('hour', hours)
      .set('minute', minutes)
      .second(0); // Optionally set seconds to 0

    // Log the selected time in 24-hour format
    console.log('Selected Time in 24-hour format:', updatedDateTime.format('YYYY-MM-DD HH:mm:ss'));

    // Update modelData with the updated date and time as a Day.js object
    SetModelData({
      ...modelData,
      bookingdatetime: updatedDateTime, // Store as a Day.js object (no UTC conversion)
      selectTime: selectedTime, // Save selected time as well
    });
  }
};



  


  const headers = {
    headers: {
      Authorization: `Bearer ${"eyJ0eXAiOiJKV1QiLCJhbGciOiJIUzI1NiJ9.eyJpc3MiOiJodHRwczovL3NldG1vcmUuZnVsbGF1dGguY29tIiwiaWF0IjoxNzE0NzMyOTA1LCJwcm9qX2lkIjoib2xkOnNldG1vcmUiLCJ0eXBlIjoidXNlciIsInN1YiI6IjlhZGU5MTQ3LWQ4MzctNGQxZi1iZTNmLTYyMDk5MWEwMGJiYiIsImV4cCI6MTcxNDc0MDEwNSwianRpIjoiZTYxYzk2QjltSlh6ZndpRiJ9.yMdvQV1iFNxlQR_VnCeTaFLkBRStt2pzM_gIS6mxSb0"}`,
    },
    "Content-Type": "application/json",
  };

  const body = {
    staff_key: "data",
    service_key: "tank cleaning",
    customer_key: "92",
    start_time: "2024-05-19T19:00Z",
    end_time: "2024-05-20T20:00Z",
    comment: "Test comment",
    label: "Test Label",
  };
  // simon

  // useEffect(()=>{
  //   // dispatch(addBooking(body));
  //   axios
  //   .post('https://developer.setmore.com/api/v1/bookingapi/appointment/create',body,headers)
  //   .then((response) => {
  //     console.log(response);
  //   });
  // },[ ])

  const Data = [
    {
      id: 1,

      HeadText: "Book a Technician",
      imagePath: bookingTechnicianImage,
      Paragraph:
        "Lorem ipsum dolor sit amet consectetur. Id placerat libero at venenatis congue nascetur elementum.",

      Text: (
        <p
          className="BookingPharaText"
          //to="https://simonfabien.setmore.com/simon" TwoTone className="BookingLink"
          onClick={() => {
            handleOpenModel();
            SetModelData({
              ...modelData,
              description: "technicalVisit",
            });
            SetModelData({
              ...modelData,
              bookingtype: "technicalVisit",
            });
          }}
        >
          Book now{" "}
        </p>
      ),
    },
    {
      id: 2,

      HeadText: "Order truck borne water",
      imagePath: waterTank,
      Paragraph:
        "Lorem ipsum dolor sit amet consectetur. Id placerat libero at venenatis congue nascetur elementum.",
      Text: (
        <Link to="https://wasa.gov.tt/WASA_home.html" className="BookingLink">
          {" "}
          Book now
        </Link>
      ),
    },
    {
      id: 3,
      HeadText: "Book Tank cleaning service",
      imagePath: orderTruck,
      Paragraph:
        "Lorem ipsum dolor sit amet consectetur. Id placerat libero at venenatis congue nascetur elementum.",
      Text: (
        <p
          className="BookingPharaText"
          //to="https://simonfabien.setmore.com/simon" className="BookingLink"
          onClick={() => {
            handleOpenModel();
            SetModelData({
              ...modelData,
              description: "Service visit",
            });
            SetModelData({
              ...modelData,
              bookingtype: "serviceVisit",
            });
          }}
        >
          Book now
        </p>
      ),
    },
  ];
  useEffect(()=>{
    if (createBookingDetails && createBookingDetails?.statusCode === 200) {
      toast.success(createBookingDetails.message);
      setOpenMadel(false);
      setModelSteps("firstStep");
      setTimeout(() => {
        window.location.reload();
      }, 1000); 
    }
    if (createBookingDetails && createBookingDetails?.statusCode === 400) {
      toast.error(createBookingDetails.message);
      setOpenMadel(false);
      setModelSteps("firstStep");
      setTimeout(() => {
        window.location.reload();
      }, 1000); 
    }
  
  })
  const handleCloseModelBooking = () => {
    console.log('model data',modelData)
    dispatch(createBooking(modelData));
    setOpenMadel(false);
    setModelSteps("firstStep");
    
    if (createBookingDetails && createBookingDetails?.statusCode === 200) {
      toast.success(createBookingDetails.message);
      setOpenMadel(false);
      setModelSteps("firstStep");
      
    }
    if (createBookingDetails && createBookingDetails?.statusCode === 400) {
      toast.error(createBookingDetails.message);
      setOpenMadel(false);
      setModelSteps("firstStep");
    }
  };
  
  

  return (
    <div className="Booking">
      <div className="Top">
        <div className="HeadTextBooking">
          <div className="HeadText">
            <p>Book Technician or Plumber</p>
          </div>
          <div className="AddButton">
            <button>
              <PhoneIcon style={{ marginRight: "10px" }} />
              <a href="tel:+91-868-294-3549" style={{color:"#fff"}}>8682943549</a>
            </button>
          </div>
        </div>
      </div>
      <div className="Center">
        <div className="Cards">
          {Data.map((data) => (
            <div className="One" key={data.id}>
              <div className="Top">
                <img
                  src={data.imagePath}
                  alt="Logo"
                  style={{ maxWidth: "100%", height: "auto", }}
                />
                {/* <ImagePlaceHolderIcon /> */}
              </div>
              <div className="Bottom">
                <div className="HeadText">{data.HeadText}</div>
                <div className="Paragraph">{data.Paragraph}</div>
                <div className="BookingArrow">
                  {data.Text} <RightArrowIcon />
                </div>
              </div>
            </div>
          ))}
        </div>
      </div>
      <div className="Bottom">
        <div className="Popup">
          <Modal
            open={openModel}
            onClose={handleCloseModel}
            aria-labelledby="parent-modal-title"
            aria-describedby="parent-modal-description"
          >
            <Box  sx={{
      ...style,
      
      // maxWidth: { xs: "100%", sm: "70%" }, // Responsive max width
      width:"80%",
      // margin: "auto", // Center the modal
      padding: "16px", // Padding for the content
      display: "flex",
      flexDirection: "column", // Column layout for mobile
      gap: "16px", // Space between elements
    }}>
               {modelSteps === "firstStep" ? (
                <div>
                   <Breadcrumbs
                     separator={<NavigateNextIcon fontSize="small" />}
                     aria-label="breadcrumb"
                   >
                     {breadcrumbs}
                   </Breadcrumbs>
                   <div className="FirstNameLastName">
                     <div className="initialName">
                       <label>First Name:</label>
                       <div className="FirstName" style={{ marginTop: "10px" }}>
                         <FormControl sx={{ width: "80%" }}>
                           <OutlinedInput
                             startAdornment={
                               <InputAdornment position="start">
                                 <AccountHumanIcon />
                               </InputAdornment>
                             }
                             name="firstname"
                             onChange={(e) =>
                               SetModelData({
                                 ...modelData,
                                 firstname: e.target.value,
                               })
                             }
                             placeholder="First name"
                             error={firstNameError}
                           />
                         </FormControl>
                         {firstNameError && (
                           <p style={{ color: "red" }}>
                            First Name is required.
                           </p>
                         )}
                       </div>
                     </div>
                     <div className="finalName">
                       <label>Last Name:</label>
                       <div className="LastName" style={{ marginTop: "10px" }}>
                         <FormControl sx={{ width: "80%" }}>
                           <OutlinedInput
                             startAdornment={
                               <InputAdornment position="start">
                                 <AccountHumanIcon />
                               </InputAdornment>
                             }
                             name="lastname"
                             onChange={(e) =>
                               SetModelData({
                                 ...modelData,
                                 lastname: e.target.value,
                               })
                             }
                             placeholder="Last name"
                             error={lastNameError}
                           />
                         </FormControl>
                         {lastNameError && (
                           <p style={{ color: "red" }}>Last Name is required.</p>
                         )}
                       </div>
                     </div>
                   </div>
                   <div className="PhoneNumberDropdown">
                     <div className="cellNumber">
                       <label>Phone:</label>
                       <div
                         className="PhoneNumber"
                         style={{ marginTop: "10px" }}
                       >
                         <MuiPhoneNumber
                            sx={{
                             '& .MuiInputBase-root': {
                               borderBottom: 'none', // Removes the bottom border
                             },
                             '& .MuiInputBase-root:before': {
                               borderBottom: 'none', // Removes the blue line before focus
                             },
                             '& .MuiInputBase-root:after': {
                               borderBottom: 'none', // Removes the blue line after focus
                             },
                             '& .MuiInputBase-root:hover:not(.Mui-disabled):before': {
                               borderBottom: 'none', // Ensures no line appears on hover
                             },
                           }}
                           placeholder="Mobile number"
                           defaultCountry={"tt"}
                           name="phonenumber"
                           onChange={(value) =>
                             SetModelData({ ...modelData, phonenumber: value })
                           }
                           required
                           error={phoneError}
                         />
                       </div>
                       {phoneError && (
                         <p style={{ color: "red" }}>Phone is required.</p>
                       )}
                     </div>

                     <div className="bookingTypeFilled">
                       <label>Booking type:</label> <br />
                       <div className="Dropdown" style={{ marginTop: "10px" }}>
                         <TextField
                           sx={{ width: "80%" }}
                          //  id="filled-select-currency"
                           variant="filled"
                           value={modelData.bookingtype}
                           disabled
                           //   select
                           InputProps={{
                             startAdornment: (
                               <InputAdornment position="start">
                                 <HomeIcon />
                               </InputAdornment>
                             ),
                           }}
                           name="bookingType"
                           // onChange={(e) =>
                           //   SetModelData({
                           //     ...modelData,
                           //     bookingType: e.target.value,
                           //   })
                           // }
                         >
                           {/* <MenuItem value="TechnicalVisit">
                             Technical visit 
                           </MenuItem>
                           <MenuItem value="ServiceVisit">
                             Service visit
                           </MenuItem> */}
                         </TextField>
                       </div>
                     </div>
                   </div>
                   <div className="AddressContainer">
                     <label>Address:</label>
                     <div className="Address" style={{ marginTop: "10px" }}>
                       <FormControl sx={{ width: "100%" }}>
                         <OutlinedInput
                           startAdornment={
                             <InputAdornment position="start">
                               <LocationIcon />
                             </InputAdornment>
                           }
                           name="bookingaddress"
                           onChange={(e) =>
                             SetModelData({
                               ...modelData,
                               bookingaddress: e.target.value,
                             })
                           }
                           placeholder="Urna urna sem, sodales nibh laoreet aliquam adipiscing pulvinar arcu."
                          //  endAdornment={
                          //    <InputAdornment position="end">
                          //      <LocationLocateIcon />
                          //    </InputAdornment>
                          //  }
                           error={addressError}
                         />
                       </FormControl>
                       {addressError && (
                         <p style={{ color: "red" }}>Address is required.</p>
                       )}
                     </div>
                   </div>
                   <div
                     className="StepOneButtons"
                     style={{
                       marginTop: "48px",
                       display: "flex",

                       gap: "12px",
                     }}
                   >
                     <div style={{ display: "flex", marginLeft: "73%" }}>
                       <div className="CancelButton">
                         <Button
                           style={{
                             height: "48px",
                             padding: "0px 24px",
                             justifyContent: "center",
                             alignItems: "center",
                             gap: "10px",
                             borderRadius: "8px",
                             border: "1px solid #CAD4DE",
                           }}
                           onClick={() => handleCloseModel()}
                         >
                           Cancel
                         </Button>
                       </div>
                       <div className="ContinueButton">
                         <Button
                           onClick={handleContinue}
                           style={{
                             height: "48px",
                             padding: "0px 24px",
                             justifyContent: "center",
                             alignItems: "center",
                             gap: "10px",
                             borderRadius: "8px",
                             background: "#4D8BFF",
                             color: "#fff",
                             fontSize: "16px",
                             marginLeft: "12px",
                           }}
                         >
                           Continue
                         </Button>
                       </div>
                     </div>
                   </div>
                 </div>
               ) : modelSteps === "secondStep" ? (
                <Container>
                  <Breadcrumbs
                    separator={<NavigateNextIcon fontSize="small" />}
                    aria-label="breadcrumb"
                  >
                    {breadcrumbsData}
                  </Breadcrumbs>
                  <div className="DateTimePicker">
                    <div className="DatePicker">
                      {/* <div style={DateTimeHeaderStyle}>Select a date:</div> */}
                      <div className="Date">
                      <div>Select a date:</div>
                      <LocalizationProvider dateAdapter={AdapterDayjs}>
  
                      <DateCalendar
            value={modelData.bookingdatetime}
            onChange={(newValue) => {
              const utcDate =newValue;
              SetModelData({
                ...modelData,
                bookingdatetime: utcDate, // Store in UTC
              });
            }}
            disablePast
            minDate={today}
            shouldDisableDate={isDisabledDate}
          />
</LocalizationProvider>


                      </div>
                    </div>
                    <div className="TimePicker">
                      <div style={DateTimeHeaderStyle}>Select a time slot:</div>
                      <div className="MrngAfnEve">
                        <div className="Morning">
                          <div style={MorAftEveStyle}>Morning</div>
                          <ToggleButtonGroup
  color="primary"
  value={alignment}
  exclusive
  onChange={(event, selectedTime) => {
    setAlignment(selectedTime);
    handleTimeSelection(selectedTime); // Convert selected time to 24-hour format and update state
  }}
  aria-label="Time Selection"
  sx={{
    marginTop: "12px",
    display: "flex",
    flexWrap: "nowrap",
  }}
>
  <ToggleButton
    value="10:00AM"
    disabled={isPastTime("10:00AM", modelData.bookingdatetime)}
  >
    10:00AM
  </ToggleButton>
  <ToggleButton
    value="10:30AM"
    disabled={isPastTime("10:30AM", modelData.bookingdatetime)}
  >
    10:30AM
  </ToggleButton>
  <ToggleButton
    value="11:00AM"
    disabled={isPastTime("11:00AM", modelData.bookingdatetime)}
  >
    11:00AM
  </ToggleButton>
  <ToggleButton
    value="11:30AM"
    disabled={isPastTime("11:30AM", modelData.bookingdatetime)}
  >
    11:30AM
  </ToggleButton>
</ToggleButtonGroup>

                        </div>
                        <div className="Morning">
                          <div style={MorAftEveStyle}>Afternoon</div>
                          <ToggleButtonGroup
  color="primary"
  value={alignment}
  exclusive
  onChange={(event, selectedTime) => {
    setAlignment(selectedTime);
    handleTimeSelection(selectedTime); // Convert selected time to 24-hour format and update state
  }}
  aria-label="Time Selection"
  sx={{
    marginTop: "12px",
    display: "flex",
    flexWrap: "wrap",
  }}
>
  <ToggleButton
    value="12:00PM"
    disabled={isPastTime("12:00PM", modelData.bookingdatetime)}
  >
    12:00PM
  </ToggleButton>
  <ToggleButton
    value="12:30PM"
    disabled={isPastTime("12:30PM", modelData.bookingdatetime)}
  >
    12:30PM
  </ToggleButton>
  <ToggleButton
    value="01:00PM"
    disabled={isPastTime("01:00PM", modelData.bookingdatetime)}
  >
    01:00PM
  </ToggleButton>
  <ToggleButton
    value="01:30PM"
    disabled={isPastTime("01:30PM", modelData.bookingdatetime)}
  >
    01:30PM
  </ToggleButton>
  <ToggleButton
    value="02:00PM"
    disabled={isPastTime("02:00PM", modelData.bookingdatetime)}
  >
    02:00PM
  </ToggleButton>
  <ToggleButton
    value="02:30PM"
    disabled={isPastTime("02:30PM", modelData.bookingdatetime)}
  >
    02:30PM
  </ToggleButton>
  <ToggleButton
    value="03:00PM"
    disabled={isPastTime("03:00PM", modelData.bookingdatetime)}
  >
    03:00PM
  </ToggleButton>
</ToggleButtonGroup>

                        </div>
                        <div className="Morning">
                          <div style={MorAftEveStyle}>Evening</div>
                          <ToggleButtonGroup
  color="primary"
  value={alignment}
  exclusive
  onChange={(event, selectedTime) => {
    setAlignment(selectedTime);
    handleTimeSelection(selectedTime); // Convert selected time to 24-hour format and update state
  }}
  aria-label="Time Selection"
  sx={{
    marginTop: "12px",
    display: "flex",
    flexWrap: "wrap",
  }}
>
  
  <ToggleButton
    value="05:00PM"
    disabled={isPastTime("05:00PM", modelData.bookingdatetime)}
  >
    05:00PM
  </ToggleButton>

  <ToggleButton
    value="05:30PM"
    disabled={isPastTime("05:30PM", modelData.bookingdatetime)}
  >
    05:30PM
  </ToggleButton>

  <ToggleButton
    value="06:00PM"
    disabled={isPastTime("06:00PM", modelData.bookingdatetime)}
  >
    06:00PM
  </ToggleButton>

  <ToggleButton
    value="06:30PM"
    disabled={isPastTime("06:30PM", modelData.bookingdatetime)}
  >
    06:30PM
  </ToggleButton>

  <ToggleButton
    value="07:00PM"
    disabled={isPastTime("07:00PM", modelData.bookingdatetime)}
  >
    07:00PM
  </ToggleButton>

  <ToggleButton
    value="07:30PM"
    disabled={isPastTime("07:30PM", modelData.bookingdatetime)}
  >
    07:30PM
  </ToggleButton>

  <ToggleButton
    value="08:00PM"
    disabled={isPastTime("08:00PM", modelData.bookingdatetime)}
  >
    08:00PM
  </ToggleButton>

  

</ToggleButtonGroup>

                        </div>
                        
                       
                      </div>
                    </div>
                  </div>
                  {errorMessage && ( // Add this block to display error
        <div style={{ color: "red", marginTop: "10px" }}>{errorMessage}</div>
      )}
                  <div className="PreNexButtonSecondModal">
                    <Button
                      style={{
                        height: "48px",
                        padding: "0px 24px",
                        justifyContent: "center",
                        alignItems: "center",
                        gap: "10px",
                        borderRadius: "8px",
                        border: "1px solid #CAD4DE",
                        margin: "0.5rem",
                      }}
                      variant="outlined"
                      onClick={() => setModelSteps("firstStep")}
                    >
                      Go Back
                    </Button>{" "}
                    {/* Add Go Back button */}
                    <Button
                      style={{
                        height: "48px",
                        padding: "0px 24px",
                        justifyContent: "center",
                        alignItems: "center",
                        gap: "10px",
                        borderRadius: "8px",
                        background: "#4D8BFF",
                        color: "#fff",
                        fontSize: "16px",
                        margin: "0.5rem",
                      }}
                      variant="outlined"
                      onClick={() => {
                        if (!alignment) {
                          setErrorMessage(" * Please select Date and time slot."); // Show error message
                          return; // Prevent navigation
                        }
                        setErrorMessage(""); // Clear the error message
                        setModelSteps(""); // Proceed to next step
                      }}
                    >
                      Confirm
                    </Button>
                  </div>
                 
                </Container>
              ) : (
                <Container>
                  <div style={{ textAlign: "center" }}>
                    <img src={SuccessImage} alt="Success" />
                    <h3>Your service request has been booked</h3>
                    <div>
                      We will reach out to you on your registered mobile number
                      for further communication
                    </div>

                    <div
                      style={{
                        display: "flex",
                        justifyContent: "space-evenly",
                        marginTop: "36px",
                      }}
                    >
                      <div
                        className="One"
                        style={{ display: "flex", alignItems: "center" }}
                      >
                        <div style={{ height: "60px", width: "60px" }}>
                          <SuccessBookingHomeIcon />
                        </div>{" "}
                        <div style={{ marginLeft: "12px" }}>
                          {modelData.bookingtype}
                        </div>
                      </div>
                      <div
                        className="Two"
                        style={{ display: "flex", alignItems: "center" }}
                      >
                        <div>
                          <SuccessBookingDateIcon />
                        </div>{" "}
                        <div style={{ marginLeft: "12px" }}>
                          {modelData.bookingdatetime
                            ? modelData.bookingdatetime.format("YYYY-MM-DD")
                            : "None"}
                        </div>
                      </div>
                      <div
                        className="Three"
                        style={{ display: "flex", alignItems: "center" }}
                      >
                        <div>
                          <SuccessBookingTimeIcon />
                        </div>
                        <div style={{ marginLeft: "12px" }}>
                          {modelData.selectTime}
                        </div>
                      </div>
                    </div>

                    <div className="AddressandStatus">
                      <div className="Address">
                        <div className="AddressData">
                          <h5>Address</h5>
                          <p>{modelData.bookingaddress}</p>
                          <Button onClick={haandleOpen}>
                            Edit Address <EditIcon />{" "}
                          </Button>
                          <Modal
                            open={openChild}
                            aria-labelledby="child-modal-title"
                            aria-describedby="child-modal-description"
                          >
                            <Box sx={{ ...styleChild }}>
                              <div>
                                <label>Edit Address</label>
                                <div
                                  className="FirstName"
                                  style={{ marginTop: "10px" }}
                                >
                                  <FormControl sx={{ width: "43ch" }}>
                                    <OutlinedInput
                                      value={modelData.bookingaddress}
                                      placeholder="EditAddress"
                                      name="bookingaddress"
                                      onChange={(e) =>
                                        SetModelData({
                                          ...modelData,
                                          bookingaddress: e.target.value,
                                        })
                                      }
                                    />
                                  </FormControl>
                                </div>
                              </div>
                              <Button
                                style={{
                                  height: "48px",
                                  padding: "0px 24px",
                                  justifyContent: "center",
                                  alignItems: "center",
                                  gap: "10px",
                                  borderRadius: "8px",
                                  background: "#4D8BFF",
                                  color: "#fff",
                                  fontSize: "16px",
                                  margin: "1rem 0rem",
                                }}
                                onClick={haandleClose}
                              >
                                Save
                              </Button>
                            </Box>
                          </Modal>
                        </div>
                      </div>
                    </div>
                    <Button
                      style={{
                        height: "48px",
                        padding: "0px 24px",
                        justifyContent: "center",
                        alignItems: "center",
                        gap: "10px",
                        borderRadius: "8px",
                        background: "#4D8BFF",
                        color: "#fff",
                        fontSize: "16px",
                        margin: "1rem 0rem",
                      }}
                      variant="outlined"
                      onClick={() => handleCloseModelBooking()}
                    >
                      Close
                    </Button>
                  </div>
                </Container>
              )}
            </Box>
          </Modal>
        </div>
      </div>
    </div>
  );
};

export default Booking;
