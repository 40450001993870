import { OTP } from "./ActionTypes";
import { OTP_RESPONSE } from "./ActionTypes";
import { MOBILE_OTP } from "./ActionTypes";
import { MOBILE_OTP_RESPONSE } from "./ActionTypes";


export const OTPVerify = (OTPCredentials) => ({
    type: OTP,
    payload: OTPCredentials,
  });
  
  export const OTPResponse = (OTPResponse) => ({
    type: OTP_RESPONSE,
    payload: OTPResponse,
  });
  export const MobileOTPVerify = (MobileOTPCredentials) => ({
    type: MOBILE_OTP,
    payload: MobileOTPCredentials,
  });
  
  export const MobileOTPResponse = (MobileOTPResponse) => ({
    type: MOBILE_OTP_RESPONSE,
    payload: MobileOTPResponse,
  });