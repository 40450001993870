import React, { useEffect, useState, useRef } from "react";
import "./adminUserProfile.css";
import TextField from "@mui/material/TextField";
import MuiPhoneNumber from "material-ui-phone-number";
import { FormControl } from "@mui/material";
import PersonOutlineOutlinedIcon from "@mui/icons-material/PersonOutlineOutlined";
import OutlinedInput from "@mui/material/OutlinedInput";
import InputAdornment from "@mui/material/InputAdornment";
import { ReactComponent as LocationIcon } from "../../../../assets/SvgIcons/LocationIcon.svg";
import { ReactComponent as LocationLocateIcon } from "../../../../assets/SvgIcons/LocationLocateIcon.svg";
import { useDispatch, useSelector } from "react-redux";
import {
  getUsetDetails,
  updateUserProfileDetails,
} from "../../../../Redux/Actions";
import Badge from "@mui/material/Badge";
import Avatar from "@mui/material/Avatar";
import CameraAltOutlinedIcon from "@mui/icons-material/CameraAltOutlined";
import { toast } from "react-toastify";
import { useNavigate } from "react-router-dom";

const UserProfile = () => {
  let dispatch = useDispatch();
  const navigate = useNavigate();
  let loginResponse = useSelector(
    (state) => state.UserProfile.getUserInformation
  );
  const Userr = useSelector((adminn) => adminn.UserProfile.getUserInformation);
  const [userID, setUserID] = useState(Userr?.data?.userid);
  const [isSubmitting, setIsSubmitting] = useState(false); // State to track submission status

  useEffect(() => {
    if (userID) {
      dispatch(getUsetDetails(userID));
    }
  }, [userID]);

  const [profileDetails, setProfileDetails] = useState({
    firstname: "",
    lastname: "",
    phonenumber: "",
    address: "",
    imagedata: null,
  });

  // Populate form fields when Userr is available
  useEffect(() => {
    if (Userr?.data) {
      setProfileDetails({
        firstname: Userr.data.firstname || "",
        lastname: Userr.data.lastname || "",
        phonenumber: Userr.data.phonenumber || "",
        address: Userr.data.address || "",
        imagedata: Userr.data.imagedata || "",
      });
    }
  }, [Userr]);

  const handlePhoneNumberChange = (value) => {
    setProfileDetails((prevDetails) => ({
      ...prevDetails,
      phonenumber: value,
    }));
  };

  const handleSubmit = async () => {
    if (isSubmitting) return; 
    setIsSubmitting(true); // Set submitting to true

    const updatedProfileDetails = {
      ...profileDetails,
      userid: Userr?.data?.userid,
      roleid: Userr?.data?.roleid || null,
    };
  
    const response = await dispatch(updateUserProfileDetails(updatedProfileDetails));
  
    // Check if the update was successful
    if (response?.statusCode === 200 || loginResponse?.statusCode === 200) {
      toast.success("Profile updated successfully!");
  
      setTimeout(() => {
        window.location.reload();
      }, 1000);
    } else {
      toast.error("Failed to update profile. Please try again.");
    }

    setIsSubmitting(false); // Reset submitting status
  };

  const hiddenFileInput = useRef(null);

  const handleInputChange = (e) => {
    const { name, files } = e.target;

    if (name === "imagedata" && files && files[0]) {
      console.log("image size", files[0]);
      if (files[0].size > 5120) {
        toast.error("Image size doesn't exceed 5KB");
        return;
      }
      const imageFile = files[0];
      const reader = new FileReader();

      reader.onloadend = () => {
        setProfileDetails((prevDetails) => ({
          ...prevDetails,
          imagedata: reader.result, // Set the Base64 image data in the state
        }));
      };

      reader.readAsDataURL(imageFile);
    } else {
      const { value } = e.target;
      setProfileDetails((prevDetails) => ({
        ...prevDetails,
        [name]: value,
      }));
    }
  };

  const handleImageUploadClick = () => {
    hiddenFileInput.current.click(); // Simulate a click on the hidden input
  };

  return (
    <div className="UserProfileMainContainer">
      <div className="avatarStyle">
        <Badge
          overlap="circular"
          anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
          badgeContent={
            <CameraAltOutlinedIcon
              onClick={handleImageUploadClick}
              style={{
                backgroundColor: "#5BD1DC",
                color: "#fff",
                borderRadius: "50%",
                padding: "4px",
                fontSize: "30px",
                cursor: "pointer",
              }}
            />
          }
        >
          <Avatar
            alt="User Avatar"
            src={profileDetails.imagedata}
            sx={{ width: 125, height: 125 }}
            style={{ cursor: "pointer" }}
          />
        </Badge>
      </div>
      <input
        type="file"
        accept="image/*"
        style={{ display: "none" }}
        ref={hiddenFileInput}
        onChange={handleInputChange}
        name="imagedata"
      />
      <div className="UserProfileContainer">
        <p className="titleFullName">Full Name</p>
        <TextField
          id="outlined-start-adornment"
          sx={{
            width: "100%",
            border: "1px solid #E9EDF2",
            borderRadius: "8px",
            margin: "1rem 0rem",
          }}
          name="firstname"
          placeholder={"First Name"}
          value={profileDetails.firstname}
          onChange={handleInputChange}
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">
                <PersonOutlineOutlinedIcon />
              </InputAdornment>
            ),
          }}
        />
        <p className="titleLastName">Last Name</p>
        <TextField
          id="outlined-start-adornment"
          sx={{
            width: "100%",
            border: "1px solid #E9EDF2",
            borderRadius: "8px",
          }}
          name="lastname"
          placeholder={"Last Name"}
          value={profileDetails.lastname}
          onChange={handleInputChange}
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">
                <PersonOutlineOutlinedIcon />
              </InputAdornment>
            ),
          }}
        />
        <p className="titleMobileNumber">Mobile Number</p>
        <MuiPhoneNumber
          defaultCountry={"tt"}
          name="phonenumber"
          variant="outlined"
          required
          enableLongNumbers
          onChange={handlePhoneNumberChange}
          sx={{
            width: "100%",
            border: "1px solid #E9EDF2",
            borderRadius: "8px",
            margin: "1rem 0rem",
          }}
          value={profileDetails.phonenumber}
          placeholder={"Mobile Number"}
          className="RegistrationPageMobileNumber"
        />
        <p className="titleAddress">Address</p>
        <FormControl sx={{ width: "100%", background: "#fff" }}>
          <OutlinedInput
            startAdornment={
              <InputAdornment position="start">
                <LocationIcon />
              </InputAdornment>
            }
            name="address"
            onChange={handleInputChange}
            placeholder="Enter your address"
            value={profileDetails.address}
          
          />
        </FormControl>
        <button 
          className="UserProfileSummitButton" 
          onClick={handleSubmit}
          disabled={isSubmitting} // Disable button if submitting
        >
          {isSubmitting ? "Updating..." : "Update"} {/* Change button text */}
        </button>
      </div>
    </div>
  );
};

export default UserProfile;
