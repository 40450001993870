import { REGISTRATION_RESPONSE } from "./ActionTypes";

const initialState = {
    RegistrationDetails: "",
};

const Registration = (state = initialState, action) => {
  
  switch (action.type) {
    case REGISTRATION_RESPONSE:
      state = {
        ...state,
        RegistrationDetails: action.payload,
      };
      break;
    default:
      state = { ...state };
      break;
  }
  return state;
};

export default Registration;
