export const GET_DEVICE_BY_ID = "GET_DEVICE_BY_ID"
export const GET_DEVICE_BY_ID_RESPONSE = "GET_DEVICE_BY_ID_RESPONSE"
export const GET_DEVICE_APPROVED = "GET_DEVICE_APPROVED"
export const GET_DEVICE_APPROVED_RESPONSE = "GET_DEVICE_APPROVED_RESPONSE"
export const GET_DEVICED_DATA_BY_SERIAL_NUMBER = "GET_DEVICED_DATA_BY_SERIAL_NUMBER"
export const GET_DEVICED_DATA_BY_SERIAL_NUMBER_RESPONSE = "GET_DEVICED_DATA_BY_SERIAL_NUMBER_RESPONSE"
export const GET_DEVICE_REJECTED = "GET_DEVICE_REJECTED"
export const GET_DEVICE_REJECTED_RESPONSE = "GET_DEVICE_REJECTED_RESPONSE"
export const DELETE_DEVICE = "DELETE_DEVICE"
export const DELETE_DEVICE_RESPONSE = "DELETE_DEVICE_RESPONSE"
export const GET_DEVICE_DATA_BY_DEVICE_ID = "GET_DEVICE_DATA_BY_DEVICE_ID"
export const GET_DEVICE_DATA_BY_DEVICE_ID_RESPONSE = "GET_DEVICE_DATA_BY_DEVICE_ID_RESPONSE"
export const GET_FORCOST_DEVICE_DATA = "GET_FORCOST_DEVICE_DATA"
export const GET_FORCOST_DEVICE_DATA_RESPONSE = "GET_FORCOST_DEVICE_DATA_RESPONSE"
export const GET_USUAGE_DEVICE_DATA = "GET_USUAGE_DEVICE_DATA"
export const GET_USUAGE_DEVICE_DATA_RESPONSE = "GET_USUAGE_DEVICE_DATA_RESPONSE"