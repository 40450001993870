import {GET_DEVICE_BY_ID_RESPONSE,
    GET_DEVICE_APPROVED_RESPONSE,
    GET_DEVICED_DATA_BY_SERIAL_NUMBER_RESPONSE,
    GET_DEVICE_REJECTED_RESPONSE,
    DELETE_DEVICE_RESPONSE,
    GET_DEVICE_DATA_BY_DEVICE_ID_RESPONSE,
    GET_FORCOST_DEVICE_DATA_RESPONSE,
    GET_USUAGE_DEVICE_DATA_RESPONSE,

} from "./ActionType"

const initialState = {
    deviceDetails: "",
    getDeviceApproved:"",
    getDeviceSerialNumber:"",
    getDeviceRejected:"",
    deleteDevice:"",
    deviceDataByDeviceId:"",
    getForcostDeviceData:"",
    getUseageDeviceData:"",
}

const DeviceList = (device = initialState, action) => {

    switch (action.type) {
        case GET_DEVICE_BY_ID_RESPONSE:
            device = {
                ...device,
                deviceDetails: action.payload
            }                           
            break;
            case GET_DEVICE_APPROVED_RESPONSE:
            device = {
                ...device,
                getDeviceApproved: action.payload
            }
            break;
            case GET_DEVICED_DATA_BY_SERIAL_NUMBER_RESPONSE:
            device = {
                ...device,
                getDeviceSerialNumber: action.payload
            }
            break;
            case GET_DEVICE_REJECTED_RESPONSE:
                device = {
                    ...device,
                    getDeviceRejected: action.payload
                }
            break;
            case DELETE_DEVICE_RESPONSE:
                device = {
                    ...device,
                    deleteDevice: action.payload
                }
            break;
            case GET_DEVICE_DATA_BY_DEVICE_ID_RESPONSE:
                device = {
                    ...device,
                    deviceDataByDeviceId: action.payload
                }
            break;
            case GET_FORCOST_DEVICE_DATA_RESPONSE:
                device = {
                    ...device,
                    getForcostDeviceData: action.payload
                }
            break;
            case GET_USUAGE_DEVICE_DATA_RESPONSE:
                device = {
                    ...device,
                    getUseageDeviceData: action.payload
                }
            break;
    

        default:
            device = { ...device }
            break

    }
    return device

}

export default DeviceList