import React, { useEffect, useState } from "react";
import { MDBDataTable } from "mdbreact";
import Styles from "./DeviceManagement.css";
import { useDispatch, useSelector } from "react-redux";
import { getAllDeviceListById, getDeviceDelete,getDeviceDataByDeviceID } from "../../../Redux/Actions";
import Button from '@mui/material/Button';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Modal from '@mui/material/Modal';
import TextField from "@mui/material/TextField";

const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: "50%",
  bgcolor: 'background.paper',
  border: 'px solid #ECF0F4',
  borderRadius: "8px",
  boxShadow: 24,
  p: 4,
};

// Utility function to calculate time ago
function timeAgo(inputDate) {
  const now = new Date();
  const pastDate = new Date(inputDate);
  const diffInMs = now - pastDate; // Difference in milliseconds

  // Time calculations
  const seconds = Math.floor(diffInMs / 1000);
  const minutes = Math.floor(seconds / 60);
  const hours = Math.floor(minutes / 60);
  const days = Math.floor(hours / 24);
  const weeks = Math.floor(days / 7);
  const months = Math.floor(days / 30); 
  const years = Math.floor(days / 365); 

  if (seconds < 60) {
    return `${seconds} seconds ago`;
  } else if (minutes < 60) {
    return `${minutes} minutes ago`;
  } else if (hours < 24) {
    return `${hours} hours ago`;
  } else if (days < 7) {
    return `${days} days ago`;
  } else if (weeks < 4) {
    return `${weeks} weeks ago`;
  } else if (months < 12) {
    return `${months} months ago`;
  } else {
    return `${years} years ago`;
  }
}

export default function DeviceManagementTable() {
  const dispatch = useDispatch();
  const deviceList = useSelector((device) => device.DeviceList.deviceDetails);
  const authUser = JSON.parse(localStorage.getItem("auth"));

  const getAllDeviceListByIdResponse = useSelector(
    (device) => device.DeviceList.deviceDataByDeviceId
  );

  // const getA

  const [selectedData, setSelectedData] = useState({});
  const [openAlert, setOpenAlert] = useState(false);
  const [openAlertEdit, setOpenAlertEdit] = useState(false);
  const [editData, setEditData] = useState({
    DeviceName: "",
    lastRefill: "",
    lastMaintanace: "",
    TankCapacity: "",
    TankType: "",
  });

  const [menuAnchorEls, setMenuAnchorEls] = useState({});

  const handleClickMenu = (event, device) => {
    setMenuAnchorEls((prev) => ({ ...prev, [device.deviceid]: event.currentTarget }));
    setSelectedData(device);
  };

  const handleCloseMenu = (deviceId) => {
    setMenuAnchorEls((prev) => ({ ...prev, [deviceId]: null }));
  };

  const handleOpenAlertEdit = () => {
    setOpenAlertEdit(true);
    setEditData({
      DeviceName: selectedData.devicename || "",
      lastRefill: new Date(selectedData.lastrefill).toLocaleDateString("en-US", { year: "numeric", month: "short", day: "numeric", timeZone: "America/Port_of_Spain" }),
      lastMaintanace: new Date(selectedData.lastmaintanance).toLocaleDateString("en-US", { year: "numeric", month: "short", day: "numeric", timeZone: "America/Port_of_Spain" }),
      TankCapacity: selectedData.tankcapacity || "",
      TankType: selectedData.tanktype || "",
    });
  };

  const handleOpenAlert = () => {
    setOpenAlert(true);
  };

  const handleCloseAlert = () => setOpenAlert(false);
  const handleCloseAlertEdit = () => setOpenAlertEdit(false);
  const [refresh, setRefresh] = useState(false); 

  const handleDeleteDevice = async () => {
    console.log('selected id', selectedData);
  
    try {
      await dispatch(getDeviceDelete(selectedData.deviceid)); // Delete device
      await dispatch(getAllDeviceListById(authUser?.userId)); // Fetch updated list
  
      setRefresh((prev) => !prev);
    } catch (error) {
      console.error('Error deleting device:', error);
    }
  };
  
  useEffect(() => {
    dispatch(getAllDeviceListById(authUser?.userId));
  }, [dispatch, authUser?.userId, refresh]);

  const data = {
    columns: [
      { label: "S.No", field: "s_no", sort: "enabled", width: 250 },
      { label: "Device Name", field: "name", sort: "enabled", width: 150 },
      { label: "WATER LEVEL", field: "waterLevel", sort: "enabled", width: 200 },
      { label: "LAST UPDATED", field: "lastUpdated", sort: "enabled", width: 100 },
      { label: "STATUS", field: "status", sort: "enabled", width: 270 },
      { label: "LAST REFILL", field: "lastRefil", sort: "enabled", width: 100 },
      { label: "LAST MAINTENANCE", field: "lastMaintanace", sort: "enabled", width: 150 },
      { field: "action",sort:"disabled" },
    ],
    rows: deviceList && deviceList?.data
      .filter(device => device.devicestatus === "ACTIVE") 
      .map((device, index) => ({
        s_no: index + 1,
        name: device.devicename,
        waterLevel: device.tankcapacity,
        status: (
          <div
            style={{
              color: device.devicestatus === "ACTIVE" ? "#0D894F" : "#545F71",
              backgroundColor: device.devicestatus === "ACTIVE" ? "#E7F4EE" : "#EEF1F4",
              width: "fit-content",
              borderRadius: "100px",
              padding: "4px 12px",
              textAlign: "center",
            }}
          >
            <div>{device.devicestatus}</div>
          </div>
        ),
        lastRefil: new Date(device.lastrefill).toLocaleDateString("en-US", {
          year: "numeric",
          month: "short",
          day: "numeric",
          timeZone: "America/Port_of_Spain" 
        }),
        lastMaintanace: new Date(device.lastmaintanance).toLocaleDateString("en-US", {
          year: "numeric",
          month: "short",
          day: "numeric",
          timeZone: "America/Port_of_Spain" 
        }),
        lastUpdated: timeAgo(device.lastrefill), // Shows "time ago"
        action: (
          <div>
           <Button onClick={(event) => handleClickMenu(event, device)}>
             <MoreVertIcon />
           </Button>
           <Menu
             anchorEl={menuAnchorEls[device.deviceid]}
             open={Boolean(menuAnchorEls[device.deviceid])}
             onClose={() => handleCloseMenu(device.deviceid)}
           >
             <MenuItem onClick={handleOpenAlert}>Delete Device</MenuItem>
          </Menu>

          <Modal open={openAlert}
                        onClose={handleCloseAlert}
                        aria-labelledby="modal-modal-title"
                        aria-describedby="modal-modal-description"
                    >
                        <Box sx={style} className="modelPopDeviceManagent">
                     <Typography sx={{ mt: 2 }}>Do you want to delete the device {selectedData.devicename}?</Typography>
                     <div className="modelPopDeviceManagentButtonContainer">
                             <button className="deviceDeletePopCancleButton" onClick={()=> handleCloseAlert()}>Cancel</button>
                             <button className="deviceDeletePopDDeleteButton" onClick={()=> { handleDeleteDevice(); handleCloseAlert()}}>Delete</button>
                         </div>
                         </Box>
                 </Modal>

          
           <Modal open={openAlertEdit} onClose={handleCloseAlertEdit}>
             <Box sx={style}>
               <Typography sx={{ mt: 2 }}>Update Device</Typography>
               <div>
                 <TextField
                   id="DeviceName"
                   label="Device Name"
                   value={editData.DeviceName}
                   fullWidth
                   margin="normal"
                 />
                 <TextField
                   id="lastRefill"
                   label="Last Refill"
                   value={editData.lastRefill}
                   fullWidth
                   margin="normal"
                 />
                 <TextField
                   id="lastMaintanace"
                   label="Last Maintenance"
                   value={editData.lastMaintanace}
                   fullWidth
                   margin="normal"
                 />
                 <TextField
                   id="TankCapacity"
                   label="Tank Capacity"
                   value={editData.TankCapacity}
                   fullWidth
                   margin="normal"
                 />
                 <TextField
                   id="TankType"
                   label="Tank Type"
                   value={editData.TankType}
                   fullWidth
                   margin="normal"
                 />
               </div>
               <div style={{ display: "flex", justifyContent: "flex-end", marginTop: "10px" }}>
                 <Button variant="contained" color="primary" onClick={handleCloseAlertEdit}>
                   Update
                 </Button>
               </div>
             </Box>
           </Modal>
         </div>
       ),
     })),
  };

  return (
    <div className={Styles.main}>
      {/* <div className={Styles.header}>
        <h4 className={Styles.heading}>DEVICE MANAGEMENT</h4>
      </div> */}
      <MDBDataTable  data={data} />
    </div>
  );
}
