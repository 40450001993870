import { GET_ALL_NOTIFICATION_RESPONSE } from "./ActionTypes";

const initialState = {
  error: "",
  getNotificationSuccessfull: "",
};

const Notification = (notification = initialState, action) => {
  switch (action.type) {
    case GET_ALL_NOTIFICATION_RESPONSE:
      notification = {
        ...notification,
        getNotificationSuccessfull: action.payload,
      };
      break;
    default:
      notification = { ...notification };
      break;
  }
  return notification;
};

export default Notification;
