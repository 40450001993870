import {METRICS} from './ActionTypes'
import {METRICS_RESPONSE} from './ActionTypes'

export const metricsData = (metricsData) => ({
    type:METRICS,
    payload:metricsData
})

export const metricsResponseData = (metricsResponseData) => ({
    type:METRICS_RESPONSE,
    payload:metricsResponseData
})

