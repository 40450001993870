import * as React from 'react';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import MoreVertSharpIcon from '@mui/icons-material/MoreVertSharp';
import RectangleCorveBorder from "../../../assets/images/RectangleCorveBorder.png";
import Styles from "./Index.module.css";
import { useDispatch, useSelector } from "react-redux";
import { useEffect } from 'react';
import { getTransactionHistory } from "../../../Redux/Actions";

export default function PaymentDataTable() {
  const dispatch = useDispatch();

  const getPaymentResponse = useSelector(
    (payment) => payment.GetPayment.transactionDetails
  );

  const UserIDDataHome = JSON.parse(localStorage.getItem("auth"));
  
  useEffect(() => {
    dispatch(getTransactionHistory(UserIDDataHome.userId));
  }, [dispatch, UserIDDataHome.userId]);

  const formatDate = (timestamp) => {
    if (!timestamp) return "";
    const date = new Date(timestamp * 1000); // Convert seconds to milliseconds
    return date.toLocaleDateString("en-TT", { timeZone: "America/Port_of_Spain" });
  };
  

  return (
    <TableContainer component={Paper} className={Styles.TableComtainerInDashboard}>
      <Table sx={{ minWidth: 650 }} aria-label="simple table" className={Styles.TableComtainerInDashboard}>
        <TableHead>
          <TableRow>
            <TableCell align="start" className={Styles.datatableWaterLevel}></TableCell>
            <TableCell align="start" className={Styles.datatableWaterLevel}>Amount</TableCell>
            <TableCell align="start" className={Styles.datatableWaterLevel}>REF ID</TableCell>
            <TableCell align="start" className={Styles.datatableWaterLevel}>Description</TableCell>
            <TableCell align="start" className={Styles.datatableWaterLevel}>Date</TableCell>
            <TableCell align="start" className={Styles.datatableWaterLevel}>PAYMENT Method</TableCell>
            <TableCell align="start" className={Styles.datatableWaterLevel}>Status</TableCell>
            <TableCell align="start" className={Styles.datatableWaterLevel}></TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {Array.isArray(getPaymentResponse?.data) 
  ? getPaymentResponse?.data?.map((row) => (
            <React.Fragment key={row.reference}>
              <TableRow className={Styles.TableRowINDashBoard}>
                <TableCell align="start" className={Styles.TableRowINDashBoardRow}>
                  <img src={RectangleCorveBorder} alt=""/>
                </TableCell>
                <TableCell align="start" className={Styles.TableRowINDashBoardRow}>{row.amount}{"  "}{row.currency}</TableCell>
                <TableCell align="start" className={Styles.TableRowINDashBoardRow}>{row.reference}</TableCell>
                <TableCell align="start" className={Styles.TableRowINDashBoardRow}>Subscription update</TableCell>
                <TableCell align="start" className={Styles.TableRowINDashBoardRow}>
                  {formatDate(row.createdat)}
                </TableCell>
                <TableCell align="start" className={Styles.TableRowINDashBoardRow}>Credit Card</TableCell>
                <TableCell align="start" className={Styles.TableRowINDashBoardRow}>
                  <div
                    style={{
                      color: row.paymentstatus === 'Failed' ? '#E46A11' : row.paymentstatus === 'Success' ? '#0D894F' : '#545F71',
                      backgroundColor: row.paymentstatus === 'Failed' ? '#FDF1E8' : row.paymentstatus === 'Success' ? '#E7F4EE' : '#EEF1F4',
                      width: 'fit-content',
                      borderRadius: '100px',
                      padding: '4px 12px',
                      textAlign: 'center',
                    }}
                  >
                    {/* <div>{row.paymentstatus}</div> */}
                    <div>Success</div>
                  </div>
                </TableCell>
                <TableCell align="start" className={Styles.TableRowINDashBoardRow}><MoreVertSharpIcon/></TableCell>
              </TableRow>
              <TableRow>
                <TableCell align="start" className={Styles.TableRowINDashBoardEmptyRow}></TableCell>
                <TableCell align="start" className={Styles.TableRowINDashBoardEmptyRow}></TableCell>
                <TableCell align="start" className={Styles.TableRowINDashBoardEmptyRow}></TableCell>
                <TableCell align="start" className={Styles.TableRowINDashBoardEmptyRow}></TableCell>
                <TableCell align="start" className={Styles.TableRowINDashBoardEmptyRow}></TableCell>
                <TableCell align="start" className={Styles.TableRowINDashBoardEmptyRow}></TableCell>
                <TableCell align="start" className={Styles.TableRowINDashBoardEmptyRow}></TableCell>
              </TableRow>
            </React.Fragment>
          )): <div>No payment data available</div>}
        </TableBody>
      </Table>
    </TableContainer>
  );
}
