import { call, put, takeEvery, takeLatest } from "redux-saga/effects";
import { Service } from "../../screens/Services/Helper.js"
import {METRICS} from './ActionTypes.js'
import { metricsResponseData} from './Action.js'

function* metricsVerify() {
    try{
        const response = yield call(
            Service.commonFetch,
            "/device/metrics-summary",
            "GET",
            null,
            true
        );
        yield put(metricsResponseData(response));
        console.log(response)

    }catch(error){

    }
}
function* metricss(){
    yield takeEvery(METRICS,metricsVerify);
}
export default metricss