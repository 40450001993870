import React from "react";
import { TextField } from "@mui/material";
import "./popup.css";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { DatePicker } from "@mui/x-date-pickers";
import { FormControl } from "@mui/material";
import OutlinedInput from "@mui/material/OutlinedInput";
import InputAdornment from "@mui/material/InputAdornment";
import { ReactComponent as LocationIcon } from "../../assets/SvgIcons/LocationIcon.svg";
import { ReactComponent as LocationLocateIcon } from "../../assets/SvgIcons/LocationLocateIcon.svg";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormHelperText from "@mui/material/FormHelperText";
import { SelectChangeEvent } from "@mui/material/Select";
import Select from "@mui/material/Select";

function PopUp() {
  const [age, setAge] = React.useState("");

  const handleChange = (event) => {
    setAge(event.target.value);
  };

  return (
    <>
      <div className="popupForm">
        <div className="innerPopup">
          <div className="firstRow">
            <div className="deviceNameProps">
              <p>Device name</p>
              <TextField
                id="outlined-start-adornment"
                className="deviceNameFeild"
                name="name"
                placeholder={"Enter device name"}
              />
            </div>
            <div className="startDate">
              <p>Last refill</p>
              <LocalizationProvider dateAdapter={AdapterDayjs}>
                <DatePicker className="calender" />
              </LocalizationProvider>
            </div>
            <div className="endDate">
              <p>Last Tank Cleaning</p>
              <LocalizationProvider dateAdapter={AdapterDayjs}>
                <DatePicker className="calender" />
              </LocalizationProvider>
            </div>
          </div>
          <div className="secondRow">
            <div className="deviceNameProps">
              <p>Number of Tanks</p>
              <TextField
                id="outlined-start-adornment"
                className="deviceNameFeild"
                name="name"
                placeholder={"This is an input field"}
              />
            </div>
            <div className="installerName">
              <p>Installer name</p>
              <TextField
                id="outlined-start-adornment"
                className="deviceNameFeild"
                name="name"
                placeholder={"Installer name"}
              />
            </div>
            <div className="installerName">
              <p>Device address</p>
              <TextField
                id="outlined-start-adornment"
                className="deviceNameFeild"
                name="name"
                placeholder={"Installer name"}
              />
            </div>
          </div>
          <div className="thirdRow">
            <div className="deviceNameProps">
              <p className="titleAddress">Device location</p>
              <FormControl sx={{ width: "100%", background: "#F8FAFC" }}>
                <OutlinedInput
                  startAdornment={
                    <InputAdornment position="start">
                      <LocationIcon />
                    </InputAdornment>
                  }
                  name="address"
                  //   onChange={(e) =>
                  //     SetModelData({
                  //       ...modelDdata,
                  //       address: e.target.value,
                  //     })
                  //   }
                  placeholder="Urna urna sem, sodales nibh laoreet aliquam adipiscing pulvinar arcu."
                  endAdornment={
                    <InputAdornment position="end">
                      <LocationLocateIcon />
                    </InputAdornment>
                  }
                />
              </FormControl>
            </div>
            <div className="tankCapacity">
              <p>Tank capacity</p>
              <TextField
                id="outlined-start-adornment"
                className="deviceNameFeild"
                name="name"
                placeholder={"Tank capacity"}
              />
            </div>
            <div className="tankType">
              <p>Tank type</p>
              <FormControl sx={{ minWidth: "100%" , backgroundColor:"#F8FAFC"}}>
                <Select
                  value={age}
                  onChange={handleChange}
                  displayEmpty
                  inputProps={{ "aria-label": "Without label" }}
                >
                  <MenuItem value="">
                    <em>None</em>
                  </MenuItem>
                  <MenuItem value={10}>Ten</MenuItem>
                  <MenuItem value={20}>Twenty</MenuItem>
                  <MenuItem value={30}>Thirty</MenuItem>
                </Select>
              </FormControl>
            </div>
          </div>
          <div className="buttons">
          <button className="denyButton">Deny</button>
          <button className="acceptButton">Accept</button>
          </div>
        </div>
      </div>
    </>
  );
}

export default PopUp;
