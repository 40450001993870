import {
  LOGIN_RESPONSE,
  FORGOT_PASSWORD_RESPONSE,
//   EMAIL_OTP_RESPONSE,
  CHANGE_PASSWORD_RESPONSE,
} from "./ActionTypes";

const initialState = {
  error: "",
  loginSuccessfull: "",
  forgotpassword: "",
//   emailOTPvalidation: "",
  changePassword: "",
};

const Login = (state = initialState, action) => {
        switch (action.type) {
            case LOGIN_RESPONSE:
            state = {
                ...state,
                loginSuccessfull: action.payload,
            };
            break;
            case FORGOT_PASSWORD_RESPONSE:
            state = {
                ...state,
                forgotpassword: action.payload,
            };
            break;
            // case EMAIL_OTP_RESPONSE:
            // state = {
            //     ...state,
            //     emailOTPvalidation: action.payload,
            // };
            // break;
            case CHANGE_PASSWORD_RESPONSE:
            state = {
                ...state,
                changePassword: action.payload,
            };
            break;
            default:
            state = { ...state };
            break;
        }
        return state;
};

export default Login;
