import React, { useEffect, useRef } from "react";
import MarkerIcon from "../../../../assets/images/MapIcon1.png";
import "./mapandgeolocation.css";
import Styles from "./Index.module.css";
import CarouselContent from "../../../pages/homePage/CarouselContent";
import { useDispatch, useSelector } from "react-redux";
import { getAllMaps } from "../../../../Redux/Actions";
import { useState } from "react";
import Wave from "react-wavify";
// import Styles from "./Index.module.css";
import ToggleButton from "@mui/material/ToggleButton";
import ToggleButtonGroup from "@mui/material/ToggleButtonGroup";
// import DataTable from "./DataTable";
import { Link } from "react-router-dom";
import Tooltip, { tooltipClasses } from "@mui/material/Tooltip";
import TooltpWaterLevel from "../../../../assets/images/TooltpWaterLevel.png";
import { styled } from "@mui/material/styles";
// import { useDispatch, useSelector } from "react-redux";
import { getDeviceDataByDeviceID } from "../../../../Redux/Actions";
import DownArrow from "../../../../assets/images/DownArrow.png";
// import CarouselContent from "./CarouselContent";
import waterdrop from "../../../../assets/videos/waterdrop.mp4";

import Box from "@mui/material/Box";
import InputLabel from "@mui/material/InputLabel";
import FormControl from "@mui/material/FormControl";
import NativeSelect from "@mui/material/NativeSelect";
// import { select } from "redux-saga/effects";
import Battery1 from "../../../../assets/images/Artboard 1@4x.png";
import Battery2 from "../../../../assets/images/Artboard 2@4x.png";
import Battery3 from "../../../../assets/images/Artboard 3@4x.png";
import Battery4 from "../../../../assets/images/Artboard 4@4x.png";
import Battery5 from "../../../../assets/images/Artboard 5@4x.png";
import Battery6 from "../../../../assets/images/Artboard 6@4x.png";
import Battery7 from "../../../../assets/images/Artboard 7@4x.png";
import Battery8 from "../../../../assets/images/Artboard 8@4x.png";
import Battery9 from "../../../../assets/images/Artboard 9@4x.png";
import Battery10 from "../../../../assets/images/Artboard 10@4x.png";
import Wifi4 from "../../../../assets/images/wifi4.png";
import Wifi2 from "../../../../assets/images/wifi2.png";
import Wifi1 from "../../../../assets/images/wifi1.png";
import Wifi3 from "../../../../assets/images/wifi3.png";
import Select from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";
import { AreaChart, Area, XAxis, YAxis, CartesianGrid } from "recharts";

const containerStyle = {
  width: "100%",
  height: "400px",
};

const center = {
  lat: 13.0843,
  lng: 80.2705,
};

const UserIDDataHome = JSON.parse(localStorage.getItem("auth"));

function MapAndGeolocation() {
  const [useAndForeCostData, setUseAndForeCostData] = useState({
    userid: UserIDDataHome?.userId,
    deviceid: "",
    option: "",
    noOfPeriods: "",
  });
  // const [deviceId, setDeviceId] = useState();
  const [takDataAnalytics, setTakDataAnalytics] = useState("Level");
  const [gallons, setGallons] = useState(false);
  const [tankWaterMesurement, setTankWaterMesument] = useState();
  const [alignment, setAlignment] = useState("Level");
  const [deviceLastUpdate, setDeviceLastUpdate] = useState();
  const [tankCleanUp, setTankCleanUp] = useState([]);
  useEffect(() =>{
    if(getAllDeviceListByIdResponse.statusCode === 200){
      function daysRemaining(futureDate) {
        const targetDate = new Date(futureDate);
        const currentDate = new Date();
        const timeDifference = targetDate - currentDate;
        const daysDifference = Math.ceil(timeDifference / (1000*60*60*24));
        return daysDifference;
      }
      // setReplaceFilter(daysRemaining(getAllDeviceListByIdResponse.data.filterReplace))
      setTankCleanUp(daysRemaining(getAllDeviceListByIdResponse.data.tankCleanup))
    }
  })

  const usgaeAndForecast = (event) => {
    if (event.target.value === "1 Week") {
      setUseAndForeCostData({
        ...useAndForeCostData,
        option: "weekly",
        noOfPeriods: "1",
      });
    }
    if (event.target.value === "1 month") {
      setUseAndForeCostData({
        ...useAndForeCostData,
        option: "monthly",
        noOfPeriods: "1",
      });
    }
    if (event.target.value === "3 month") {
      setUseAndForeCostData({
        ...useAndForeCostData,
        option: "monthly",
        noOfPeriods: "3",
      });
    }
    if (event.target.value === "6 month") {
      setUseAndForeCostData({
        ...useAndForeCostData,
        option: "monthly",
        noOfPeriods: "6",
      });
    }

    // setUseAndForeCostData(event.target.value)
  };

  const CustomToggleButton = styled(ToggleButton)({
    "&.Mui-selected, &.Mui-selected:hover": {
      color: "white",
      backgroundColor: "#4d8bff",
      textTransform: "capitalize",
    },
  });
  const LightTooltip = styled(({ className, ...props }) => (
    <Tooltip {...props} classes={{ popper: className }} />
  ))(({ theme }) => ({
    [`& .${tooltipClasses.tooltip}`]: {
      border: "1px solid var(--neutrals-100, #ECF0F4)",
      background: "var(--neutral-white, #FFF)",
      boxShadow:
        "0px 11px 15px -4px rgba(16, 26, 40, 0.03), 0px 7px 6px -3px rgba(16, 26, 40, 0.04)",
      borderRadius: "8px",
    },
  }));
  const handleChange = (event, newAlignment) => {
    setAlignment(newAlignment);
    setTakDataAnalytics(newAlignment);
  };

  const handleChangeGallons = (event) => {
    if (event.target.value === "volvo") {
      setGallons(true);
    } else {
      setGallons(false);
    }
  };

  const getDeviceIdFromHomePage = (event) => {
    setDeviceId(event.target.value);
    setUseAndForeCostData({
      ...useAndForeCostData,
      deviceid: event.target.value,
    });
  };

  const dispatch = useDispatch();
  const heatList = useSelector((heatdata) => heatdata.Map.mapResponse);
  const devicedata = useSelector(
    (deviceData) => deviceData.DeviceList.deviceDataByDeviceId
  );

  const [deviceId, setDeviceId] = useState();
  const getAllDeviceListByIdResponse = useSelector(
    (device) => device.DeviceList.deviceDataByDeviceId
  );
  console.log(
    "getAllDeviceListByIdResponsegetAllDeviceListByIdResponse",
    getAllDeviceListByIdResponse
  );
  useEffect(() => {
    dispatch(getDeviceDataByDeviceID(deviceId));
  }, [deviceId]);

  console.log("devicedata", devicedata);

  console.log("heatList", heatList);

  // console.log("huckhuck",huck)
  useEffect(() => {
    dispatch(getAllMaps());
  }, [dispatch]);

  const mapRef = useRef(null);
  const hello = () => {
    console.log("hello baby");
  };
  useEffect(() => {
    if (window.google && mapRef.current) {
      const map = new window.google.maps.Map(mapRef.current, {
        center: center,
        zoom: 10,
      });

      heatList.data?.forEach((card) => {
        // Extract latitude and longitude from devicelocation
        const [latStr, lngStr] = card.devicelocation.split(", ");

        const lat = parseFloat(latStr);
        const lng = parseFloat(lngStr);

        if (!isNaN(lat) && !isNaN(lng)) {
          // Ensure lat and lng are valid numbers
          const marker = new window.google.maps.Marker({
            position: { lat, lng },
            map: map,
            icon: {
              url: MarkerIcon,
              scaledSize: new window.google.maps.Size(50, 50),
            },
            onClick: { hello },
          });

          const contentString = `
            <div style="width:248px;gap: 8px;">
              <h3 style="width: 176px;height: 18px;font-size: 18px;font-weight: 800;line-height: 18px;text-align: left;color:#181C20">${card.devicename}</h3>
              <p style="font-family: Figtree;font-size: 10px;font-weight: 400;line-height: 16px;text-align: left;color: #6A7A8A;">${card.createdtime}</p>
              <div style="display:flex; justify-content: space-between; ">
                <div style="width:85px;height:24px;padding: 4px 12px 4px 12px;gap: 8px;border-radius: 100px;opacity: 0px;background: #EAFBF0;"><p style="width: 61px;height: 16px;gap: 0px;opacity: 0px;font-size: 12px;font-weight: 400;line-height: 16px;text-align: center;color: #147138;">${card.deviceStatus}</p></div>
                <div style="width:106px;height:36px;gap: 4px;opacity: 0px;margin-top:-0.5rem">
                  <p style="width: 106px;height: 16px;width: 106px;height: 16px;;color:#6A7A8A;">Last updated</p>
                  <p style="width: 93px;height: 16px;gap: 0px;opacity: 0px;color:#181C20;margin-top:-0.5rem">${card.createdtime}</p>
                </div>
              </div>
              <hr style="margin-top:1rem">
              <div style="display:flex; justify-content: space-between;margin-top:-1rem ">
                <p style="width: 141px;height: 16px;font-size: 12px;font-weight: 400;line-height: 16px;text-align: left;color:#6A7A8A">Water level</p>
                <p style="font-family: Figtree;font-size: 12px;font-weight: 400;line-height: 16px;text-align: left;width: 105px;height: 16px;gap: 0px;opacity: 0px;color:#181C20">${card.waterlevel}</p>
              </div>
              <div style="display:flex; justify-content: space-between;margin-top:-0.5rem  ">
                <p style="width: 151px;height: 16px;gap: 0px;opacity: 0px;width: 171px;height: 16px;gap: 0px;opacity: 0px;color:#6A7A8A">Next cleaning date</p>
                <p style="width: 109px;height: 16px;gap: 0px;opacity: 0px;font-family: Figtree;font-size: 12px;font-weight: 400;line-height: 16px;text-align: left;color:#181C20">${card.nextmaintence}</p>
              </div>
            </div>
          `;

          const infowindow = new window.google.maps.InfoWindow({
            content: contentString,
          });

          marker.addListener("click", () => {
            infowindow.open(map, marker);
            console.log("Marker clicked:", heatList);
            console.log("Marker Clicked 2 :", card);
            setDeviceId(card.deviceid);
          });
        }
      });
    }
  }, [heatList, dispatch]);

  useEffect(() => {
    if (
      getAllDeviceListByIdResponse?.data?.waterlevelPercentage > 0 &&
      getAllDeviceListByIdResponse?.data?.waterlevelPercentage <= 5
    ) {
      setTankWaterMesument(390);
    } else if (
      getAllDeviceListByIdResponse?.data?.waterlevelPercentage > 5 &&
      getAllDeviceListByIdResponse?.data?.waterlevelPercentage <= 10
    ) {
      setTankWaterMesument(380);
    } else if (
      getAllDeviceListByIdResponse?.data?.waterlevelPercentage > 10 &&
      getAllDeviceListByIdResponse?.data?.waterlevelPercentage <= 15
    ) {
      setTankWaterMesument(360);
    } else if (
      getAllDeviceListByIdResponse?.data?.waterlevelPercentage > 15 &&
      getAllDeviceListByIdResponse?.data?.waterlevelPercentage <= 20
    ) {
      setTankWaterMesument(330);
    } else if (
      getAllDeviceListByIdResponse?.data?.waterlevelPercentage > 20 &&
      getAllDeviceListByIdResponse?.data?.waterlevelPercentage <= 25
    ) {
      setTankWaterMesument(300);
    } else if (
      getAllDeviceListByIdResponse?.data?.waterlevelPercentage > 25 &&
      getAllDeviceListByIdResponse?.data?.waterlevelPercentage <= 30
    ) {
      setTankWaterMesument(280);
    } else if (
      getAllDeviceListByIdResponse?.data?.waterlevelPercentage > 30 &&
      getAllDeviceListByIdResponse?.data?.waterlevelPercentage <= 35
    ) {
      setTankWaterMesument(260);
    } else if (
      getAllDeviceListByIdResponse?.data?.waterlevelPercentage > 35 &&
      getAllDeviceListByIdResponse?.data?.waterlevelPercentage <= 40
    ) {
      setTankWaterMesument(240);
    } else if (
      getAllDeviceListByIdResponse?.data?.waterlevelPercentage > 40 &&
      getAllDeviceListByIdResponse?.data?.waterlevelPercentage <= 45
    ) {
      setTankWaterMesument(220);
    } else if (
      getAllDeviceListByIdResponse?.data?.waterlevelPercentage > 45 &&
      getAllDeviceListByIdResponse?.data?.waterlevelPercentage <= 50
    ) {
      setTankWaterMesument(200);
    } else if (
      getAllDeviceListByIdResponse?.data?.waterlevelPercentage > 50 &&
      getAllDeviceListByIdResponse?.data?.waterlevelPercentage <= 55
    ) {
      setTankWaterMesument(180);
    } else if (
      getAllDeviceListByIdResponse?.data?.waterlevelPercentage > 55 &&
      getAllDeviceListByIdResponse?.data?.waterlevelPercentage <= 60
    ) {
      setTankWaterMesument(160);
    } else if (
      getAllDeviceListByIdResponse?.data?.waterlevelPercentage > 60 &&
      getAllDeviceListByIdResponse?.data?.waterlevelPercentage <= 65
    ) {
      setTankWaterMesument(140);
    } else if (
      getAllDeviceListByIdResponse?.data?.waterlevelPercentage > 65 &&
      getAllDeviceListByIdResponse?.data?.waterlevelPercentage <= 70
    ) {
      setTankWaterMesument(110);
    } else if (
      getAllDeviceListByIdResponse?.data?.waterlevelPercentage > 70 &&
      getAllDeviceListByIdResponse?.data?.waterlevelPercentage <= 75
    ) {
      setTankWaterMesument(100);
    } else if (
      getAllDeviceListByIdResponse?.data?.waterlevelPercentage > 75 &&
      getAllDeviceListByIdResponse?.data?.waterlevelPercentage <= 80
    ) {
      setTankWaterMesument(85);
    } else if (
      getAllDeviceListByIdResponse?.data?.waterlevelPercentage > 80 &&
      getAllDeviceListByIdResponse?.data?.waterlevelPercentage <= 85
    ) {
      setTankWaterMesument(65);
    } else if (
      getAllDeviceListByIdResponse?.data?.waterlevelPercentage > 85 &&
      getAllDeviceListByIdResponse?.data?.waterlevelPercentage <= 90
    ) {
      setTankWaterMesument(50);
    } else if (
      getAllDeviceListByIdResponse?.data?.waterlevelPercentage > 90 &&
      getAllDeviceListByIdResponse?.data?.waterlevelPercentage <= 95
    ) {
      setTankWaterMesument(40);
    } else if (
      getAllDeviceListByIdResponse?.data?.waterlevelPercentage > 95 &&
      getAllDeviceListByIdResponse?.data?.waterlevelPercentage <= 99
    ) {
      setTankWaterMesument(30);
    } else if (
      getAllDeviceListByIdResponse?.data?.waterlevelPercentage >= 99 &&
      getAllDeviceListByIdResponse?.data?.waterlevelPercentage <= 100
    ) {
      // Show max minus 1% fluctuation for wave effect at near full capacity
      const maxMeasurement = -0; // The tank measurement for 100%
      const fluctuation =
        100 - getAllDeviceListByIdResponse?.data?.waterlevelPercentage; // This will be 1 when at 99%
      setTankWaterMesument(maxMeasurement + fluctuation); // Adjust by 1% for wave effect
    } else if (getAllDeviceListByIdResponse?.data?.waterlevelPercentage >= 0) {
      setTankWaterMesument(480);
    }
  }, [getAllDeviceListByIdResponse?.data]);

  return (
    <>
      <div>
        <div style={containerStyle} ref={mapRef} />
      </div>
      <div className={Styles.HomePageContainer}>
        <div className={Styles.HomePageMyTankContainer}>
          <div className={Styles.HomePageMyTankContainerHeader}>
            <Box sx={{ minWidth: 160 }}>
              <h2> {getAllDeviceListByIdResponse?.data?.devicename}</h2>
            </Box>

            {/* <Box sx={{ minWidth: 100 }}>
              <FormControl
                fullWidth
                className={Styles.useageAndForecostDropDown}
              >
                <NativeSelect
                  inputProps={{
                    id: "uncontrolled-native",
                  }}
                  onChange={usgaeAndForecast}
                  defaultValue={"1 Week"}
                  className={Styles.DRopDownListTankDetailsdata}
                >
                  <option value={"1 Week"}>1 Week </option>
                  <option value={"1 month"}>1 month</option>
                  <option value={"3 month"}>3 month</option>
                  <option value={"6 month"}>6 month</option>
                </NativeSelect>
              </FormControl>
            </Box> */}

            {/* <ToggleButtonGroup
              color="primary"
              value={alignment}
              exclusive
              onChange={handleChange}
              aria-label="Platform"
              className={Styles.HomePageToggleButtonsContainer}
            >
              <CustomToggleButton
                value="Level"
                className={Styles.HomePageToggleButtons}
              >
                Level
              </CustomToggleButton>
              <CustomToggleButton
                value="Usage"
                className={Styles.HomePageToggleButtons}
              >
                Usage
              </CustomToggleButton>
              <CustomToggleButton
                value="Forecast"
                className={Styles.HomePageToggleButtons}
              >
                Forecast
              </CustomToggleButton>
            </ToggleButtonGroup> */}
          </div>

          {/* {getAllDeviceListByIdResponse?.data && getAllDeviceListByIdResponse.data?.map((data) => {
            return(  */}

          <>
            <div className={Styles.HomePageHeaderTankAndQuickDetails}>
              {takDataAnalytics === "Level" ? (
                <>
                  <LightTooltip
                    title={
                      <div
                        className={Styles.homepageTankContainerTooltipContent}
                      >
                        <img src={TooltpWaterLevel} alt="" />
                        <div
                          className={
                            Styles.homepageTankContainerTooltipWaterlevelTextContent
                          }
                        >
                          <p
                            className={
                              Styles.homepageTankContainerTooltipWaterlevelTextContentText
                            }
                          >
                            Current level
                          </p>
                          <p
                            className={
                              Styles.homepageTankContainerTooltipWaterlevelTextContentTextStatus
                            }
                          >
                            Low
                          </p>
                        </div>
                        <div
                          className={
                            Styles.homepageTankContainerTooltipWaterlevelNumberContainer
                          }
                        >
                          <p
                            className={
                              Styles.homepageTankContainerTooltipWaterlevelNumberContainerText
                            }
                          >
                            {Math.floor(
                              getAllDeviceListByIdResponse?.data
                                ?.waterlevelPercentage
                            )}
                            %
                          </p>
                          <p
                            className={
                              Styles.homepageTankContainerTooltipWaterlevelNumberContainerPercentage
                            }
                          >
                            {Math.floor(
                              getAllDeviceListByIdResponse?.data?.waterlevel
                            )}
                            %
                          </p>
                        </div>
                      </div>
                    }
                    arrow
                    followCursor
                  >
                    <div className={Styles.HomePageTankContainer}>
                      <div className={Styles.HomePageChartLines}>
                        <p className={Styles.HomePageChartLinesLine}>100% </p>
                        <p className={Styles.HomePageChartLinesLine}>
                          80%{" "}
                          <span
                            className={
                              Styles.HomePageChartLinesLineMesurementMinimum
                            }
                          />
                        </p>
                        <p className={Styles.HomePageChartLinesLine}>
                          60%{" "}
                          <span
                            className={Styles.HomePageChartLinesLineMesurementOne}
                          />
                        </p>
                        <p className={Styles.HomePageChartLinesLine}>
                          40%{" "}
                          <span
                            className={Styles.HomePageChartLinesLineMesurementTwo}
                          />
                        </p>
                        <p className={Styles.HomePageChartLinesLine}>
                          20%{" "}
                          <span
                            className={Styles.HomePageChartLinesLineMesurementThree}
                          />
                        </p>
                        <p className={Styles.HomePageChartLinesLine}>
                          10%{" "}
                          <span
                            className={Styles.HomePageChartLinesLineMesurementFour}
                          />
                        </p>
                        <p className={Styles.HomePageChartLinesLine}>
                          0%{" "}
                          <span
                            className={Styles.HomePageChartLinesLineMesurementFive}
                          />
                        </p>
                      </div>

                      <div className={Styles.TankModelContent}>
                        <p class={Styles.HomePageTankCloser}></p>
                        <p class={Styles.HomePageTankBody}>
                          <Wave
                            mask="url(#mask)"
                            fill="#4D8BFF"
                            options={{
                              points: 15,
                              speed: 0.2,
                              amplitude: 15,
                              height: tankWaterMesurement,
                            }}
                            className={Styles.TankWaves}
                          >
                            <defs>
                              <linearGradient
                                id="gradient"
                                gradientTransform="rotate(90)"
                              >
                                <stop offset="0.6" stopColor="#4D8BFF" />
                                <stop offset="1" stopColor="white" />
                              </linearGradient>
                              <mask id="mask">
                                <rect
                                  x="0"
                                  y="0"
                                  width="2000"
                                  height="520"
                                  fill="url(#gradient)"
                                />
                              </mask>
                            </defs>
                          </Wave>
                        </p>
                      </div>
                    </div>
                  </LightTooltip>
                  <div className={Styles.HomePageQuickStats}>
                    <div className={Styles.HomepageLitersAndGallonsContainer}>
                      <p className={Styles.HomePageQuickStatsText}>
                        Quick stats:
                      </p>
                      <select
                        name="cars"
                        id="cars"
                        className={Styles.HomepageLitersAndGallonsContent}
                        onChange={handleChangeGallons}
                      >
                        <option value="saab" onClick={() => setGallons(true)}>
                          Gallons
                        </option>
                        <option Value="volvo" onClick={() => setGallons(false)}>
                          liters
                        </option>
                      </select>
                    </div>
                    <div
                      className={
                        Styles.HomePageAverageDailyUsageOfWaterContainer
                      }
                    >
                      <p
                        className={
                          Styles.HomePageAverageDailyUsageOfWaterContainerIcon
                        }
                      >
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="14"
                          height="18"
                          viewBox="0 0 14 18"
                          fill="none"
                        >
                          <path
                            d="M7 17C10.3136 17 13 14.3136 13 11C13 5.4 7 1 7 1C7 1 1 5.4 1 11C1 14.3136 3.6864 17 7 17Z"
                            stroke="#4D8BFF"
                            stroke-width="1.59995"
                            stroke-linecap="round"
                            stroke-linejoin="round"
                          />
                          <path
                            d="M1 11.0001C1 14.3137 3.6864 17.0001 7 17.0001C10.3136 17.0001 13 14.3137 13 11.0001C13 11.0001 9.4 12.2001 7 11.0001C4.6 9.80013 1 11.0001 1 11.0001Z"
                            stroke="#4D8BFF"
                            stroke-width="1.59995"
                            stroke-linejoin="round"
                          />
                        </svg>
                      </p>
                      <div className={Styles.HomePageAverageBox}>
                        <p className={Styles.HomePageAverageBoxText}>
                          Current Water Level
                        </p>
                        <p
                          className={Styles.HomePageAverageBoxAveragePercentage}
                        >
                          {/* {gallons === false ? <>{Math.floor(liveData?.waterlevelPercentage)}%</> : <p className={Styles.literstoGallonsConvertion}>{Math.floor(liveData?.waterlevelPercentage)}%</p>}
                            
                             */}
                          {getAllDeviceListByIdResponse?.data
                            ?.waterlevelPercentage ? (
                            gallons === false ? (
                              <>
                                {Math.floor(
                                  getAllDeviceListByIdResponse?.data
                                    ?.waterlevelPercentage
                                )}
                                %
                              </>
                            ) : (
                              <p className={Styles.literstoGallonsConvertion}>
                                {Math.floor(
                                  getAllDeviceListByIdResponse?.data
                                    ?.waterlevelPercentage
                                )}
                                %
                              </p>
                            )
                          ) : (
                            ""
                          )}
                          <span
                            className={
                              Styles.HomePageAverageBoxCurrentWaterLevel
                            }
                          >
                            {/* {liveData?.actualVolume ? (
                              gallons === false ? (
                                <>
                                  ({Math.floor(liveData?.actualVolume)}/
                                  {liveData?.tankcapacity}G)
                                </>
                              ) : (
                                <p className={Styles.literstoGallonsConvertion}>
                                  ({Math.floor(liveData?.actualVolume * 3.7)}
                                  /3700L)
                                </p>
                              )
                            ) : (
                              "" 
                            )} */}
                          </span>
                        </p>
                      </div>
                    </div>
                    <div
                      className={
                        Styles.HomePageAverageDailyUsageOfWaterContainer
                      }
                    >
                      <p
                        className={
                          Styles.HomePageAverageDailyUsageOfWaterContainerIcon
                        }
                      >
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="14"
                          height="18"
                          viewBox="0 0 14 18"
                          fill="none"
                        >
                          <path
                            d="M7 17C10.3136 17 13 14.3136 13 11C13 5.4 7 1 7 1C7 1 1 5.4 1 11C1 14.3136 3.6864 17 7 17Z"
                            stroke="#4D8BFF"
                            stroke-width="1.59995"
                            stroke-linecap="round"
                            stroke-linejoin="round"
                          />
                          <path
                            d="M1 11.0001C1 14.3137 3.6864 17.0001 7 17.0001C10.3136 17.0001 13 14.3137 13 11.0001C13 11.0001 9.4 12.2001 7 11.0001C4.6 9.80013 1 11.0001 1 11.0001Z"
                            stroke="#4D8BFF"
                            stroke-width="1.59995"
                            stroke-linejoin="round"
                          />
                        </svg>
                      </p>
                      <div className={Styles.HomePageAverageBox}>
                        <p className={Styles.HomePageAverageBoxText}>
                          Estimated Time To Empty
                        </p>
                        <p
                          className={Styles.HomePageAverageBoxAveragePercentage}
                        >
                          {/* {/ Check if liveData is being fetched /} */}
                          {getAllDeviceListByIdResponse?.data === undefined ||
                          getAllDeviceListByIdResponse?.data === null ? (
                            "" // Display an empty value if liveData is undefined or null */}
                          ) : (
                            <>
                              {/* {/ Show liveData.estimatedUsage, even if it is 0 /} */}
                              {gallons === false ? (
                                <>
                                  {Math.floor(
                                    getAllDeviceListByIdResponse?.data
                                      ?.estimatedUsage
                                  )}
                                </>
                              ) : (
                                <p className={Styles.literstoGallonsConvertion}>
                                  {Math.floor(
                                    getAllDeviceListByIdResponse?.data
                                      ?.estimatedUsage
                                  )}
                                </p>
                              )}

                              {/* {/ Show "Days left" as long as liveData.estimatedUsage is not undefined or null /} */}
                              <span
                                className={
                                  Styles.HomePageAverageBoxEstimatedUsage
                                }
                              >
                                Days left
                              </span>
                            </>
                          )}
                        </p>
                      </div>
                    </div>
                    <div
                      className={
                        Styles.HomePageAverageDailyUsageOfWaterContainer
                      }
                    >
                      <p
                        className={
                          Styles.HomePageAverageDailyUsageOfWaterContainerIcon
                        }
                      >
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="14"
                          height="18"
                          viewBox="0 0 14 18"
                          fill="none"
                        >
                          <path
                            d="M7 17C10.3136 17 13 14.3136 13 11C13 5.4 7 1 7 1C7 1 1 5.4 1 11C1 14.3136 3.6864 17 7 17Z"
                            stroke="#4D8BFF"
                            stroke-width="1.59995"
                            stroke-linecap="round"
                            stroke-linejoin="round"
                          />
                          <path
                            d="M1 11.0001C1 14.3137 3.6864 17.0001 7 17.0001C10.3136 17.0001 13 14.3137 13 11.0001C13 11.0001 9.4 12.2001 7 11.0001C4.6 9.80013 1 11.0001 1 11.0001Z"
                            stroke="#4D8BFF"
                            stroke-width="1.59995"
                            stroke-linejoin="round"
                          />
                        </svg>
                      </p>
                      <div className={Styles.HomePageAverageBox}>
                        <p className={Styles.HomePageAverageBoxText}>
                          Average Daily Use
                        </p>
                        <p
                          className={Styles.HomePageAverageBoxAveragePercentage}
                        >
                          {getAllDeviceListByIdResponse?.data?.actualVolume ? (
                            gallons === false ? (
                              <>
                                (
                                {Math.floor(
                                  getAllDeviceListByIdResponse?.data
                                    ?.actualVolume
                                )}
                                /
                                {
                                  getAllDeviceListByIdResponse?.data
                                    ?.tankcapacity
                                }
                                G)
                              </>
                            ) : (
                              <p className={Styles.literstoGallonsConvertion}>
                                (
                                {Math.floor(
                                  getAllDeviceListByIdResponse?.data
                                    ?.actualVolume * 3.7
                                )}
                                /3700L)
                              </p>
                            )
                          ) : (
                            "" // Empty output if actualVolume is not present
                          )}

                          <span
                            className={
                              Styles.HomePageAverageBoxAveragePercentageAdvance
                            }
                          >
                            {/* {averageDailyUse}% */}
                            {/* {gallons === false ? <>{liveData?.averageDailyUse}%</> : <p className={Styles.literstoGallonsConvertion}>{Math.floor(liveData?.averageDailyUse)}%</p>} */}
                          </span>
                        </p>
                      </div>
                    </div>
                  </div>{" "}
                </>
              ) : takDataAnalytics === "Usage" ? (
                <AreaChart
                  width={600}
                  height={400}
                  // data={UseageData.data}
                  margin={{
                    top: 10,
                    right: 20,
                    left: 0,
                    bottom: 0,
                  }}
                >
                  <CartesianGrid strokeDasharray="3 3" />
                  <XAxis dataKey="timestamp" />
                  <YAxis dataKey="usage" />
                  <Tooltip />
                  <Area
                    type="monotone"
                    dataKey="usage"
                    stroke="#5BD1DC"
                    fill="#abe8eb"
                  />
                </AreaChart>
              ) : (
                <AreaChart
                  width={600}
                  height={400}
                  // data={ForeCostData.data}
                  margin={{
                    top: 10,
                    right: 20,
                    left: 0,
                    bottom: 0,
                  }}
                >
                  <CartesianGrid strokeDasharray="3 3" />
                  <XAxis dataKey="date" />
                  <YAxis dataKey="usage" />
                  <Tooltip />
                  <Area
                    type="monotone"
                    dataKey="usage"
                    stroke="rgb(53, 162, 255)"
                    fill="rgba(53, 162, 235, 0.5)"
                  />
                </AreaChart>
              )}
            </div>
            <div className={Styles.HomePageMyTankSignalAndBatteryContainer}>
              <div className={Styles.HomePageMyTankSignalAndBatteryCart}>
                <div
                  className={
                    Styles.HomePageMyTankSignalAndBatteryCartIconContent
                  }
                >
                  <p
                    className={
                      Styles.HomePageMyTankSignalAndBatteryCartIconTitle
                    }
                  >
                    Battery Status
                  </p>
                  <p
                    className={
                      Styles.HomePageMyTankSignalAndBatteryCartIconText
                    }
                  >
                    {getAllDeviceListByIdResponse?.data?.batterystatus}
                  </p>
                </div>
                <p className={Styles.HomePageMyTankSignalAndBatteryCartIcon}>
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="24"
                    height="24"
                    viewBox="0 0 24 24"
                    fill="none"
                  >
                    <path
                      d="M17 6C17.7956 6 18.5587 6.31607 19.1213 6.87868C19.6839 7.44129 20 8.20435 20 9V10H21C21.2652 10 21.5196 10.1054 21.7071 10.2929C21.8946 10.4804 22 10.7348 22 11V13C22 13.2652 21.8946 13.5196 21.7071 13.7071C21.5196 13.8946 21.2652 14 21 14H20V15C20 15.7956 19.6839 16.5587 19.1213 17.1213C18.5587 17.6839 17.7956 18 17 18H5C4.20435 18 3.44129 17.6839 2.87868 17.1213C2.31607 16.5587 2 15.7956 2 15V9C2 8.20435 2.31607 7.44129 2.87868 6.87868C3.44129 6.31607 4.20435 6 5 6H17ZM16.998 7.5H5C4.62712 7.50002 4.26761 7.63892 3.99158 7.88962C3.71555 8.14032 3.5428 8.48484 3.507 8.856L3.5 9V15C3.49999 15.3727 3.63876 15.7321 3.88925 16.0081C4.13974 16.2841 4.48402 16.457 4.855 16.493L5 16.5H16.998C17.3707 16.5 17.7301 16.3612 18.0061 16.1108C18.2821 15.8603 18.455 15.516 18.491 15.145L18.498 15V9C18.498 8.62727 18.3592 8.2679 18.1088 7.99189C17.8583 7.71589 17.514 7.54303 17.143 7.507L16.998 7.5ZM6.003 8.999H7C7.24493 8.99903 7.48134 9.08896 7.66437 9.25172C7.84741 9.41447 7.96434 9.63875 7.993 9.882L8 10V14C7.99997 14.2449 7.91004 14.4813 7.74728 14.6644C7.58453 14.8474 7.36025 14.9643 7.117 14.993L7 14.999H6.003C5.75791 14.9989 5.52138 14.9089 5.33833 14.7459C5.15527 14.5829 5.03843 14.3584 5.01 14.115L5.003 14V10C5.00279 9.75489 5.0926 9.51825 5.25538 9.335C5.41817 9.15176 5.64258 9.03468 5.886 9.006L6.003 9V8.999Z"
                      fill="#D74525"
                    />
                  </svg>
                  {getAllDeviceListByIdResponse?.data?.batterystatus > "0%" &&
                  getAllDeviceListByIdResponse?.data?.batterystatus <= "10%" ? (
                    <img src={Battery1} className={Styles.Battery} />
                  ) : getAllDeviceListByIdResponse?.data?.batterystatus >
                      "10%" &&
                    getAllDeviceListByIdResponse?.data?.batterystatus <=
                      "20%" ? (
                    <img src={Battery2} className={Styles.Battery} />
                  ) : getAllDeviceListByIdResponse?.data?.batterystatus >
                      "20%" &&
                    getAllDeviceListByIdResponse?.data?.batterystatus <=
                      "30%" ? (
                    <img src={Battery3} className={Styles.Battery} />
                  ) : getAllDeviceListByIdResponse?.data?.batterystatus >
                      "30%" &&
                    getAllDeviceListByIdResponse?.data?.batterystatus <=
                      "40%" ? (
                    <img src={Battery4} className={Styles.Battery} />
                  ) : getAllDeviceListByIdResponse?.data?.batterystatus >
                      "40%" &&
                    getAllDeviceListByIdResponse?.data?.batterystatus <=
                      "50%" ? (
                    <img src={Battery5} className={Styles.Battery} />
                  ) : getAllDeviceListByIdResponse?.data?.batterystatus >
                      "50%" &&
                    getAllDeviceListByIdResponse?.data?.batterystatus <=
                      "60%" ? (
                    <img src={Battery6} className={Styles.Battery} />
                  ) : getAllDeviceListByIdResponse?.data?.batterystatus >
                      "60%" &&
                    getAllDeviceListByIdResponse?.data?.batterystatus <=
                      "70%" ? (
                    <img src={Battery7} className={Styles.Battery} />
                  ) : getAllDeviceListByIdResponse?.data?.batterystatus >
                      "70%" &&
                    getAllDeviceListByIdResponse?.data?.batterystatus <=
                      "80%" ? (
                    <img src={Battery8} className={Styles.Battery} />
                  ) : getAllDeviceListByIdResponse?.data?.batterystatus >
                      "80%" &&
                    getAllDeviceListByIdResponse?.data?.batterystatus <=
                      "90%" ? (
                    <img src={Battery9} className={Styles.Battery} />
                  ) : getAllDeviceListByIdResponse?.data?.batterystatus >
                      "90%" &&
                    getAllDeviceListByIdResponse?.data?.batterystatus <=
                      "100%" ? (
                    <img src={Battery10} className={Styles.Battery} />
                  ) : (
                    ""
                  )}{" "}
                </p>
              </div>
              <div className={Styles.HomePageMyTankSignalAndBatteryCart}>
                <div
                  className={
                    Styles.HomePageMyTankSignalAndBatteryCartIconContent
                  }
                >
                  <p
                    className={
                      Styles.HomePageMyTankSignalAndBatteryCartIconTitle
                    }
                  >
                    Signal Strength
                  </p>
                  <p
                    className={
                      Styles.HomePageMyTankSignalAndBatteryCartIconText
                    }
                  >
                    {getAllDeviceListByIdResponse?.data?.wifistatus}
                  </p>
                </div>
                <p className={Styles.HomePageMyTankSignalAndBatteryCartIcon}>
                  {getAllDeviceListByIdResponse?.data?.wifistatus === "Okay" ? (
                    <img src={Wifi2} className={Styles.Battery} />
                  ) : getAllDeviceListByIdResponse?.data?.wifistatus ===
                    "Amazing" ? (
                    <img src={Wifi4} className={Styles.Battery} />
                  ) : getAllDeviceListByIdResponse?.data?.wifistatus ===
                    "Very Good" ? (
                    <img src={Wifi3} className={Styles.Battery} />
                  ) : getAllDeviceListByIdResponse?.data?.wifistatus ===
                    "Not Good" ? (
                    <img src={Wifi1} className={Styles.Battery} />
                  ) : (
                    ""
                  )}
                </p>
              </div>
            </div>
          </>
          {/* );
          })} */}
        </div>
        <div className="devicemain"> 
        <div
          className={
            Styles.HomePageDeviceManagementAndHardwareInformationContainer
          }
        >
            <div className={Styles.HomePageDeviceManagement}>
              <div className={Styles.HomePageDeviceManagementHeaderContent}>
                <p className={Styles.HomePageDeviceManagementText}>
                  Device Maintenance
                </p>
              </div>
              <div className={Styles.HomePageDeviceManagementTextContent}>
                <div className={Styles.HomePageDeviceManagementContentData}>
                  <p
                    className={Styles.HomePageDeviceManagementContentDataCircle}
                  ></p>
                  <p className={Styles.HomePageDeviceManagementContentDataText}>
                    Replace filter
                  </p>
                  <p
                    className={
                      Styles.HomePageDeviceManagementContentDataTextWork
                    }
                  >
                    in every
                  </p>
                </div>
                <p className={Styles.ReplaceFilter}>{`${"180"}`}Days</p>
              </div>
              <div className={Styles.HomePageDeviceManagementTextContent}>
                <div className={Styles.HomePageDeviceManagementContentData}>
                  <p
                    className={Styles.HomePageDeviceManagementContentDataCircle}
                  ></p>
                  <p className={Styles.HomePageDeviceManagementContentDataText}>
                    Tank cleanup
                  </p>
                  <p
                    className={
                      Styles.HomePageDeviceManagementContentDataTextWork
                    }
                  >
                    in
                  </p>
                </div>
                <p
                  className={
                    Styles.HomePageDeviceManagementContentDataTextWorkDays
                  }
                >
                   {`${tankCleanUp || ""}`}Days
                  {/* {getAllDeviceListByIdResponse?.data?.tankCleanup}Days */}
                </p>
              </div>

              <div className={Styles.HomePageDeviceManagementTextContentLine}>
                <div className={Styles.HomePageDeviceManagementContentData}>
                  <p
                    className={Styles.HomePageDeviceManagementContentDataCircle}
                  ></p>
                  <p className={Styles.HomePageDeviceManagementContentDataText}>
                    Lorem ipsum dolor sit amet consectetur. Id placerat libero
                    at venenatis congue nascetur elementum.
                  </p>
                </div>
              </div>
          </div>
         
        </div>

        <div className={Styles.HomePageDeviceManagement}>
        <video height="273" className={Styles.HomePageVideoContnet}
          autoPlay
          controls
          >
            <source src={waterdrop} type="video/mp4" />
          </video>
          {/* <div className={Styles.HomePageDeviceManagement}> */}
          {/* <img src={waterdrop}  className={Styles.HomePageDeviceManagement}></img> */}
          </div>
        </div>
        
      </div>

      <div></div>
    </>
  );
}

export default MapAndGeolocation;
