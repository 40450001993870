import { call, put, takeEvery, takeLatest } from "redux-saga/effects";
import {  GET_ALL_NOTIFICATION } from "./ActionTypes";
import { getAllNotificationResponse } from "./Action";
import { Service } from "../../screens/Services/Helper.js"

function* getNotification({ payload: getAllNotification }) {
    try {
        const response = yield call(
          Service.commonFetch,
          `/notification/notificationByUserId?userid= ${getAllNotification}`,
          "GET",
          null,
          true
        );
    yield put(getAllNotificationResponse(response));
    console.log(response)
  } catch (error) {

  }
}

function* notification() {
  yield takeEvery(GET_ALL_NOTIFICATION, getNotification);
 
}

export default notification;
