import React, { useEffect, useState } from "react";
import { MDBDataTable } from "mdbreact";
import { ReactComponent as MyBookingsTableRightIcon } from "../../../../../assets/SvgIcons/MyBookingsTableRightIcon.svg";
import Rectangle from "../../../../../assets/images/Rectangle.png";
import { Link } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import {
  getAdminClosedBooking,
  getBookingStatusUpdate,
} from "../../../../../Redux/Actions";
import Styles from "./../../../../pages/DeviceManagement/DeviceManagement.css";

const AdminBookingClose = () => {
  const dispatch = useDispatch();
  const getAllBookingDetails = useSelector(
    (booking) => booking.Booking.getAdminBookingCloseDetails
  );
  const [userIID, setUserIID] = useState(34);
  const [userID, setUserID] = useState({
    bookingstatus: "COMPLETED",
    // userid: "94"
  });

  useEffect(() => {
    dispatch(getAdminClosedBooking(userID));
  }, [userID, dispatch]);

  const handleStatusChange = (bookingId, newStatus) => {
    const bookingStatusUpdateInfo = {
      bookingid: bookingId, 
      bookingstatus: newStatus,
      userid: userIID,
    };
    dispatch(getBookingStatusUpdate(bookingStatusUpdateInfo)); // Dispatching the update action with new status
  };
  // Format the data for MDBDataTable
  const data = {
    columns: [
      { label: "Device Name", field: "deviceName", sort: "asc", width: 200 },
      {
        label: "Customer Name",
        field: "customerName",
        sort: "asc",
        width: 200,
      },
      { label: "Service", field: "service", sort: "asc", width: 200 },
      { label: "Booking Date", field: "bookingDate", sort: "asc", width: 200 },
      { label: "Status", field: "status", sort: "asc", width: 200 },
      // { label: "Action", field: "action", sort: "asc", width: 200 },
    ],
    rows: getAllBookingDetails?.data?.map((data) => ({
      deviceName: data.description, 
      customerName: data.firstname, 
      service: data.description, 
      bookingDate: new Date(data.bookingdatetime).toLocaleDateString("en-US", { 
        year: 'numeric', 
        month: 'short', 
        day: 'numeric', 
        timeZone: 'America/Port_of_Spain' // Trinidad and Tobago time zone 
    }),
      status: (
        <div
        style={{
          color: data.bookingstatus === 'CREATED' ? '#28a745'  
                : data.bookingstatus === 'INPROGRESS' ? '#ffc107' 
                : data.bookingstatus === 'COMPLETED' ? '#dc3545'  
                : '#545F71',  
          backgroundColor: data.bookingstatus === 'CREATED' ? '#F7FEF5'  
                          : data.bookingstatus === 'INPROGRESS' ? '#FEFCF5'  
                          : data.bookingstatus === 'COMPLETED' ? '#FEF7F5'  
                          : '#EEF1F4',  
          width: 'fit-content',
          borderRadius: '100px',
          padding: '4px 12px',
          textAlign: 'left',
          // margin: '0rem .5rem',
          position: 'relative'
        }}
        >
          <select
            defaultValue={data.bookingstatus}
            onChange={(e) => handleStatusChange(data._id, e.target.value)} 
            style={{
              appearance: "none", 
              border: "none", 
              background: "transparent",
              padding: "4px 12px",
              fontSize: "14px",
              color: "inherit",
            }}
          >
            <option value="CREATED">Created</option>
            <option value="INPROGRESS">InProgress</option>
            <option value="COMPLETED">Closed</option>
          </select>
          <span
      style={{
        position: 'absolute',
        right: '10px',
        top: '50%',
        transform: 'translateY(-50%)',
        pointerEvents: 'none',
        fontSize: '12px',
      }}
    >
      &#9662;
    </span>
        </div>
      ),
      // action: (
      //   <div style={{ display: "flex", alignItems: "center" }}>
      //     <Link
      //       to="/booking_details"
      //       className="LinkedBookingDetails"
      //       style={{ color: "#4D8BFF" }}
      //     >
      //       View booking
      //     </Link>
      //     <MyBookingsTableRightIcon />
      //   </div>
      // ),
    })),
  };

  return (
    <div className={Styles.activeTable}>
      <MDBDataTable responsive data={data} entries={10} />
    </div>
  );
};

export default AdminBookingClose;
