// import React from 'react'
// import Table from '@mui/material/Table';
// import TableBody from '@mui/material/TableBody';
// import  TableCell from '@mui/material/TableCell';
// import  TableContainer from '@mui/material/TableContainer';
// import  TableHead from '@mui/material/TableHead';
// import  TableRow from '@mui/material/TableRow';
// import  Paper from '@mui/material/Paper';
// import './paymentHistory.css'
// import Menu from"../../../assets/images/menuDotsIcon.png"
// import Profile1 from '../../../assets/images/profileCoverDp.png'
// import Rectangle from "../../../assets/images/rectangleShape.png"
// import { TabsContext } from '@mui/base';
// import ExpandLessIcon from '@mui/icons-material/ExpandLess';
// import UnfoldMoreIcon from '@mui/icons-material/UnfoldMore';
// import Pagination from '@mui/material/Pagination';
// import Stack from '@mui/material/Stack';


// function createData(name,id,status,amount,dollar,plan,date){
// return{name,id,status,amount,dollar,plan,date};
// }

// const rows=[
//   createData("Sydney Boyer","#39201","Failed","$41","USD","Splash","Sep 14, 11:56 PM"),
//   createData("Todd Morar","#39201","Failed","$41","USD","Trickle","Sep 14, 11:56 PM"),
//   createData("Clayton Hackett","#39201","Success","$41","USD","Splash","Sep 14, 11:56 PM"),
//   createData("Sydney Boyer","#39201","Success","$41","USD","Splash","Sep 14, 11:56 PM"),
//   createData("Todd Morar","#39201","Success","$41","USD","Splash","Sep 14, 11:56 PM"),
//   createData("Clayton Hackett","#39201","Success","$41","USD","Splash","Sep 14, 11:56 PM"),
//   createData("Sydney Boyer","#39201","Success","$41","USD","Splash","Sep 14, 11:56 PM"),
//   createData("Todd Morar","#39201","Success","$41","USD","Splash","Sep 14, 11:56 PM"),
//   createData("Sydney Boyer","#39201","Success","$41","USD","Splash","Sep 14, 11:56 PM"),
//   createData("Clayton Hackett","#39201","Success","$41","USD","Splash","Sep 14, 11:56 PM"),
// ]


// export default function UsersAndDevicesPage() {
//   return (
//     <>
//         <div>
//             <div className='paymentTitle'>
//                 <p>Payment history</p>
//             </div>
//             <div className='tableContainer' >
//               <TableContainer>
//                 <Table>
//                   <TableHead>
//                     <TableRow>
//                     <TableCell className='tableHead'>  </TableCell>
//                       <TableCell className='tableHead'>NAME</TableCell>
//                       <TableCell className='tableHead'>REF ID</TableCell>
//                       <TableCell className='tableHead'>STATUS</TableCell>
//                       <TableCell className='tableHead'>AMOUNT</TableCell>
//                       <TableCell className='tableHead'>CURRENT PLAN</TableCell>
//                       <TableCell className='tableHead'>TRANSACTION DATE</TableCell>
//                      </TableRow>
//                   </TableHead>
//                   <TableBody>
//                     {rows.map((row)=>(
//                       <>
//                       <TableRow
//                       key={row.name} className='bodyHead'
//                       >
//                         <TableCell  className='tableBody1' align="left"><img className="img1" src={Rectangle}/><img  className="img2" src={Profile1}/></TableCell>
//                         <TableCell  className='tableBody' align="left">{row.name}</TableCell>
//                         <TableCell className='tableBody' align="left">{row.id}</TableCell>
//                         <TableCell className='tableBody' align="left"><span  style={{
//                                 color: row.status === 'Failed' ? '#A9281E' : row.status === 'Success' ? '#0D894F' : 'black', 
//                                 backgroundColor: row.status === 'Failed' ? '#FDEDEC' : row.status === 'Success' ? '#EAFBF0' : 'white',
//                                 width : 'fit-content',
//                                 borderRadius: '100px',
//                                 padding: '4px 12px', 
//                                textAlign:'center',  

//                                 }}>{row.status}</span></TableCell>
//                         <TableCell className='tableBody' align="left"><span>{row.amount}</span><span className='dollarColor'>{row.dollar}</span></TableCell>
//                         <TableCell className='tableBodyClean' align="left">{row.plan}</TableCell>
//                         <TableCell className='tableBody' align="left">{row.date}</TableCell>
//                         <TableCell className='tableBodyLast'><img src={Menu}/></TableCell>
//                       </TableRow>
//                      <TableRow className='emptyRow'>
//                       <TableCell className='emptyCell'></TableCell>
//                       <TableCell className='emptyCell'></TableCell>
//                       <TableCell className='emptyCell'></TableCell>
//                       <TableCell className='emptyCell'></TableCell>
//                       <TableCell className='emptyCell'></TableCell>
//                       <TableCell className='emptyCell'></TableCell>
//                       <TableCell className='emptyCell'></TableCell>
//                      </TableRow>
//                       </>
//                     ))}
//                   </TableBody>
//                 </Table>
//               </TableContainer>
//             </div>
//             <div>
//             <Stack spacing={2}>
//               <Pagination count={10} />
//             </Stack>
//             </div>
//         </div>
//         <hr className='line'></hr>
//     </>
    
//   )
// }


















import React from 'react';
import { MDBDataTable } from 'mdbreact';
import './paymentHistory.css';
import Menu from "../../../assets/images/menuDotsIcon.png";
import Profile1 from '../../../assets/images/profileCoverDp.png';
import Rectangle from "../../../assets/images/rectangleShape.png";

const PaymentHistory = () => {
    // Sample data to populate the table
    const rows = [
      {
          profile: (
              <div className="profileContainer">
                  <img className="img1" src={Rectangle} alt="Background" />
                  <img className="img2" src={Profile1} alt="Profile" />
              </div>
          ),
          name: "Sydney Boyer",
          id: "#39201",
          status: (
              <span style={{
                  color: '#A9281E',
                  backgroundColor: '#FDEDEC',
                  borderRadius: '100px',
                  padding: '4px 12px',
                  textAlign: 'center',
              }}>
                  Failed
              </span>
          ),
          amount: "$41",
          dollar: "USD",
          plan: "Splash",
          date: "Sep 14, 11:56 PM",
          menu: <img src={Menu} alt="Menu" />
      },
      {
          profile: (
              <div className="profileContainer">
                  <img className="img1" src={Rectangle} alt="Background" />
                  <img className="img2" src={Profile1} alt="Profile" />
              </div>
          ),
          name: "Todd Morar",
          id: "#39201",
          status: (
              <span style={{
                  color: '#A9281E',
                  backgroundColor: '#FDEDEC',
                  borderRadius: '100px',
                  padding: '4px 12px',
                  textAlign: 'center',
              }}>
                  Failed
              </span>
          ),
          amount: "$41",
          dollar: "USD",
          plan: "Trickle",
          date: "Sep 14, 11:56 PM",
          menu: <img src={Menu} alt="Menu" />
      },
      {
          profile: (
              <div className="profileContainer">
                  <img className="img1" src={Rectangle} alt="Background" />
                  <img className="img2" src={Profile1} alt="Profile" />
              </div>
          ),
          name: "Clayton Hackett",
          id: "#39201",
          status: (
              <span style={{
                  color: '#0D894F',
                  backgroundColor: '#EAFBF0',
                  borderRadius: '100px',
                  padding: '4px 12px',
                  textAlign: 'center',
              }}>
                  Success
              </span>
          ),
          amount: "$41",
          dollar: "USD",
          plan: "Splash",
          date: "Sep 14, 11:56 PM",
          menu: <img src={Menu} alt="Menu" />
      },
      {
          profile: (
              <div className="profileContainer">
                  <img className="img1" src={Rectangle} alt="Background" />
                  <img className="img2" src={Profile1} alt="Profile" />
              </div>
          ),
          name: "Sydney Boyer",
          id: "#39201",
          status: (
              <span style={{
                  color: '#0D894F',
                  backgroundColor: '#EAFBF0',
                  borderRadius: '100px',
                  padding: '4px 12px',
                  textAlign: 'center',
              }}>
                  Success
              </span>
          ),
          amount: "$41",
          dollar: "USD",
          plan: "Splash",
          date: "Sep 14, 11:56 PM",
          menu: <img src={Menu} alt="Menu" />
      },
      {
          profile: (
              <div className="profileContainer">
                  <img className="img1" src={Rectangle} alt="Background" />
                  <img className="img2" src={Profile1} alt="Profile" />
              </div>
          ),
          name: "Todd Morar",
          id: "#39201",
          status: (
              <span style={{
                  color: '#0D894F',
                  backgroundColor: '#EAFBF0',
                  borderRadius: '100px',
                  padding: '4px 12px',
                  textAlign: 'center',
              }}>
                  Success
              </span>
          ),
          amount: "$41",
          dollar: "USD",
          plan: "Splash",
          date: "Sep 14, 11:56 PM",
          menu: <img src={Menu} alt="Menu" />
      },
      {
          profile: (
              <div className="profileContainer">
                  <img className="img1" src={Rectangle} alt="Background" />
                  <img className="img2" src={Profile1} alt="Profile" />
              </div>
          ),
          name: "Clayton Hackett",
          id: "#39201",
          status: (
              <span style={{
                  color: '#0D894F',
                  backgroundColor: '#EAFBF0',
                  borderRadius: '100px',
                  padding: '4px 12px',
                  textAlign: 'center',
              }}>
                  Success
              </span>
          ),
          amount: "$41",
          dollar: "USD",
          plan: "Splash",
          date: "Sep 14, 11:56 PM",
          menu: <img src={Menu} alt="Menu" />
      },
      {
          profile: (
              <div className="profileContainer">
                  <img className="img1" src={Rectangle} alt="Background" />
                  <img className="img2" src={Profile1} alt="Profile" />
              </div>
          ),
          name: "Sydney Boyer",
          id: "#39201",
          status: (
              <span style={{
                  color: '#0D894F',
                  backgroundColor: '#EAFBF0',
                  borderRadius: '100px',
                  padding: '4px 12px',
                  textAlign: 'center',
              }}>
                  Success
              </span>
          ),
          amount: "$41",
          dollar: "USD",
          plan: "Splash",
          date: "Sep 14, 11:56 PM",
          menu: <img src={Menu} alt="Menu" />
      },
      {
          profile: (
              <div className="profileContainer">
                  <img className="img1" src={Rectangle} alt="Background" />
                  <img className="img2" src={Profile1} alt="Profile" />
              </div>
          ),
          name: "Todd Morar",
          id: "#39201",
          status: (
              <span style={{
                  color: '#0D894F',
                  backgroundColor: '#EAFBF0',
                  borderRadius: '100px',
                  padding: '4px 12px',
                  textAlign: 'center',
              }}>
                  Success
              </span>
          ),
          amount: "$41",
          dollar: "USD",
          plan: "Splash",
          date: "Sep 14, 11:56 PM",
          menu: <img src={Menu} alt="Menu" />
      },
      {
          profile: (
              <div className="profileContainer">
                  <img className="img1" src={Rectangle} alt="Background" />
                  <img className="img2" src={Profile1} alt="Profile" />
              </div>
          ),
          name: "Sydney Boyer",
          id: "#39201",
          status: (
              <span style={{
                  color: '#0D894F',
                  backgroundColor: '#EAFBF0',
                  borderRadius: '100px',
                  padding: '4px 12px',
                  textAlign: 'center',
              }}>
                  Success
              </span>
          ),
          amount: "$41",
          dollar: "USD",
          plan: "Splash",
          date: "Sep 14, 11:56 PM",
          menu: <img src={Menu} alt="Menu" />
      },
      {
          profile: (
              <div className="profileContainer">
                  <img className="img1" src={Rectangle} alt="Background" />
                  <img className="img2" src={Profile1} alt="Profile" />
              </div>
          ),
          name: "Clayton Hackett",
          id: "#39201",
          status: (
              <span style={{
                  color: '#0D894F',
                  backgroundColor: '#EAFBF0',
                  borderRadius: '100px',
                  padding: '4px 12px',
                  textAlign: 'center',
              }}>
                  Success
              </span>
          ),
          amount: "$41",
          dollar: "USD",
          plan: "Splash",
          date: "Sep 14, 11:56 PM",
          menu: <img src={Menu} alt="Menu" />
      },
      {
        profile: (
            <div className="profileContainer">
                <img className="img1" src={Rectangle} alt="Background" />
                <img className="img2" src={Profile1} alt="Profile" />
            </div>
        ),
        name: "Sydney Boyer",
        id: "#39201",
        status: (
            <span style={{
                color: '#A9281E',
                backgroundColor: '#FDEDEC',
                borderRadius: '100px',
                padding: '4px 12px',
                textAlign: 'center',
            }}>
                Failed
            </span>
        ),
        amount: "$41",
        dollar: "USD",
        plan: "Splash",
        date: "Sep 14, 11:56 PM",
        menu: <img src={Menu} alt="Menu" />
    },
    {
        profile: (
            <div className="profileContainer">
                <img className="img1" src={Rectangle} alt="Background" />
                <img className="img2" src={Profile1} alt="Profile" />
            </div>
        ),
        name: "Todd Morar",
        id: "#39201",
        status: (
            <span style={{
                color: '#A9281E',
                backgroundColor: '#FDEDEC',
                borderRadius: '100px',
                padding: '4px 12px',
                textAlign: 'center',
            }}>
                Failed
            </span>
        ),
        amount: "$41",
        dollar: "USD",
        plan: "Trickle",
        date: "Sep 14, 11:56 PM",
        menu: <img src={Menu} alt="Menu" />
    },
    {
        profile: (
            <div className="profileContainer">
                <img className="img1" src={Rectangle} alt="Background" />
                <img className="img2" src={Profile1} alt="Profile" />
            </div>
        ),
        name: "Clayton Hackett",
        id: "#39201",
        status: (
            <span style={{
                color: '#0D894F',
                backgroundColor: '#EAFBF0',
                borderRadius: '100px',
                padding: '4px 12px',
                textAlign: 'center',
            }}>
                Success
            </span>
        ),
        amount: "$41",
        dollar: "USD",
        plan: "Splash",
        date: "Sep 14, 11:56 PM",
        menu: <img src={Menu} alt="Menu" />
    },
    {
        profile: (
            <div className="profileContainer">
                <img className="img1" src={Rectangle} alt="Background" />
                <img className="img2" src={Profile1} alt="Profile" />
            </div>
        ),
        name: "Sydney Boyer",
        id: "#39201",
        status: (
            <span style={{
                color: '#0D894F',
                backgroundColor: '#EAFBF0',
                borderRadius: '100px',
                padding: '4px 12px',
                textAlign: 'center',
            }}>
                Success
            </span>
        ),
        amount: "$41",
        dollar: "USD",
        plan: "Splash",
        date: "Sep 14, 11:56 PM",
        menu: <img src={Menu} alt="Menu" />
    },
    {
        profile: (
            <div className="profileContainer">
                <img className="img1" src={Rectangle} alt="Background" />
                <img className="img2" src={Profile1} alt="Profile" />
            </div>
        ),
        name: "Todd Morar",
        id: "#39201",
        status: (
            <span style={{
                color: '#0D894F',
                backgroundColor: '#EAFBF0',
                borderRadius: '100px',
                padding: '4px 12px',
                textAlign: 'center',
            }}>
                Success
            </span>
        ),
        amount: "$41",
        dollar: "USD",
        plan: "Splash",
        date: "Sep 14, 11:56 PM",
        menu: <img src={Menu} alt="Menu" />
    },
    {
        profile: (
            <div className="profileContainer">
                <img className="img1" src={Rectangle} alt="Background" />
                <img className="img2" src={Profile1} alt="Profile" />
            </div>
        ),
        name: "Clayton Hackett",
        id: "#39201",
        status: (
            <span style={{
                color: '#0D894F',
                backgroundColor: '#EAFBF0',
                borderRadius: '100px',
                padding: '4px 12px',
                textAlign: 'center',
            }}>
                Success
            </span>
        ),
        amount: "$41",
        dollar: "USD",
        plan: "Splash",
        date: "Sep 14, 11:56 PM",
        menu: <img src={Menu} alt="Menu" />
    },
    {
        profile: (
            <div className="profileContainer">
                <img className="img1" src={Rectangle} alt="Background" />
                <img className="img2" src={Profile1} alt="Profile" />
            </div>
        ),
        name: "Sydney Boyer",
        id: "#39201",
        status: (
            <span style={{
                color: '#0D894F',
                backgroundColor: '#EAFBF0',
                borderRadius: '100px',
                padding: '4px 12px',
                textAlign: 'center',
            }}>
                Success
            </span>
        ),
        amount: "$41",
        dollar: "USD",
        plan: "Splash",
        date: "Sep 14, 11:56 PM",
        menu: <img src={Menu} alt="Menu" />
    },
    {
        profile: (
            <div className="profileContainer">
                <img className="img1" src={Rectangle} alt="Background" />
                <img className="img2" src={Profile1} alt="Profile" />
            </div>
        ),
        name: "Todd Morar",
        id: "#39201",
        status: (
            <span style={{
                color: '#0D894F',
                backgroundColor: '#EAFBF0',
                borderRadius: '100px',
                padding: '4px 12px',
                textAlign: 'center',
            }}>
                Success
            </span>
        ),
        amount: "$41",
        dollar: "USD",
        plan: "Splash",
        date: "Sep 14, 11:56 PM",
        menu: <img src={Menu} alt="Menu" />
    },
    {
        profile: (
            <div className="profileContainer">
                <img className="img1" src={Rectangle} alt="Background" />
                <img className="img2" src={Profile1} alt="Profile" />
            </div>
        ),
        name: "Sydney Boyer",
        id: "#39201",
        status: (
            <span style={{
                color: '#0D894F',
                backgroundColor: '#EAFBF0',
                borderRadius: '100px',
                padding: '4px 12px',
                textAlign: 'center',
            }}>
                Success
            </span>
        ),
        amount: "$41",
        dollar: "USD",
        plan: "Splash",
        date: "Sep 14, 11:56 PM",
        menu: <img src={Menu} alt="Menu" />
    },
    {
        profile: (
            <div className="profileContainer">
                <img className="img1" src={Rectangle} alt="Background" />
                <img className="img2" src={Profile1} alt="Profile" />
            </div>
        ),
        name: "Clayton Hackett",
        id: "#39201",
        status: (
            <span style={{
                color: '#0D894F',
                backgroundColor: '#EAFBF0',
                borderRadius: '100px',
                padding: '4px 12px',
                textAlign: 'center',
            }}>
                Success
            </span>
        ),
        amount: "$41",
        dollar: "USD",
        plan: "Splash",
        date: "Sep 14, 11:56 PM",
        menu: <img src={Menu} alt="Menu" />
    },
      
      
  ];
  

    const data = {
        columns: [
            { label: '', field: 'profile', sort: 'asc', width: 150 },
            { label: 'NAME', field: 'name', sort: 'asc', width: 200 },
            { label: 'REF ID', field: 'id', sort: 'asc', width: 150 },
            { label: 'STATUS', field: 'status', sort: 'asc', width: 150 },
            { label: 'AMOUNT', field: 'amount', sort: 'asc', width: 100 },
            { label: 'CURRENT PLAN', field: 'plan', sort: 'asc', width: 150 },
            { label: 'TRANSACTION DATE', field: 'date', sort: 'asc', width: 200 },
            { label: '', field: 'menu', sort: 'asc', width: 50 }
        ],
        rows: rows
    };

    return (
        <div>
            <div className='paymentTitle'>
                <p>Payment History</p>
            </div>
            <div className='tableContainer'>
                <MDBDataTable
                    responsive
                    data={data}
                    paging={true}
                    entries={10}
                    searching={false}
                    noBottomColumns
                />
            </div>
        </div>
    );
}

export default PaymentHistory;
